import React, { createRef, useState } from 'react';
import { axiosApi } from 'src/services/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { error as errorToast } from 'src/components/Basicos/funcoes';
// import { warn } from 'src/components/Basicos/funcoes';

type Token = {
    access_token: string;
    expires_in: number;
    usuario?: string;
};

type LoginForm = {
    email?: string;
    password?: string;
};

const Login: React.FunctionComponent<RouteComponentProps> = ({ history }) => {

    if (localStorage.getItem('hasTheSessionExpired') === 'true') {
        errorToast('Sessão expirada');
        localStorage.removeItem('hasTheSessionExpired');
    }

    const [form, setForm] = useState<LoginForm>({ email: "", password: "" });
    const iconRef = createRef<HTMLDivElement>();
    const [isRevealPassword, setIsRevealPassword] = useState<Boolean>(false);
    const [errorLogin, setErroLogin] = useState<boolean>();

    const togglePassword = (): void => {
        setIsRevealPassword(!isRevealPassword);
    }
    const handleForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        setErroLogin(false);
        event.preventDefault();
        try {
            const response = await axiosApi.post<Token>(`auth/login-master`, form);
            const { access_token, expires_in, usuario } = response.data;
            localStorage.setItem('token-iserv', access_token);
            localStorage.setItem('iserv-expires', expires_in.toString());
            localStorage.setItem('usuario', JSON.stringify(usuario))
            axiosApi.defaults.headers.common['authorization'] = `bearer ${access_token}`;
            history.push('/');
            window.location.reload()
        } catch (error) {
            setErroLogin(true);
            // warn('Usuário ou senha inválido!');
            // console.log(error.response.data.Erro);
        }
    };

    const handleOnchangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className="grid grid-cols-2 justify-items-center">
            <div className="col-span-2 md:col-span-1 lg:col-span-1 xl:w-8/12 w-full h-screen image">
                <img className="mx-auto mt-8"
                    src={require("src/assets/login/icon.svg")}
                    alt="logo" />
                <p className="mt-8 titulo">Login</p>

                <div id="form-login">
                    <form onSubmit={handleForm} className="flex flex-col mt-8">
                        <input
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="text"
                            onChange={handleOnchangeInput}
                            required={true}
                            className={`mx-auto input-login pl-3 ${errorLogin && 'treme-animation'}`}
                        />

                        <input
                            name="password"
                            placeholder="Senha"
                            type={isRevealPassword ? "text" : "password"}
                            onChange={handleOnchangeInput}
                            required={true}
                            className={`mx-auto my-3 input-login pl-3 ${errorLogin && 'treme-animation'}`}
                        />

                        <span onClick={togglePassword} ref={iconRef}>

                            {isRevealPassword ?
                                <FontAwesomeIcon icon={faEye} className="customIcon" /> :
                                <FontAwesomeIcon icon={faEyeSlash} className="customIcon" />
                            }
                        </span>

                        <button
                            type="submit"
                            className="btn-login mx-auto"
                        >Entrar</button>
                    </form>

                </div>
            </div>
            <div className="invisible md:visible md:col-span-1  w-full h-screen coluna">
                <span className="align-baseline texto-coluna">TODOS OS SERVIÇOS, UM SÓ LUGAR</span>
            </div>
        </div>
    );
}

export default withRouter(Login);