import React, { useEffect, useState } from 'react';
// import SelectForm from 'src/components/Basicos/Form/SelectForm';
import { success } from 'src/components/Basicos/funcoes';
import ToggleSwitch from 'react-switch';
import { axiosApi } from 'src/services/axiosInstance';
import './style.css';

interface Dados {
    id_plano_anuncio: number;
    ds_descricao_plano?: string;
    nr_quantidade_visualizacao?: number;
    valor_plano?: number;
    st_ativo: boolean;
}

type Props = {
    dados?: Dados;
    closeModal: any;
    dataArray: any;
}
// type FuncionalidadePlano = {
//     id_tipo_funcionalidade?: number | undefined;
//     descricao?: string | undefined;
//     value?: any | undefined;
// }
const AnuncioPlano: React.FC<Props> = ({ dados, closeModal, dataArray }: Props) => {
    const [data, setData] = useState<Dados>();
    // const [selectOptions, setSelectOptions] = useState<Array<FuncionalidadePlano>>([]);
    const [checkedValue, setIsChecked] = useState<boolean | undefined>(false);

    const onChangeEventInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { name, value, } = event.target;
        setData((prevState: any) => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        if (dados){
            setData({
                ...dados,
                id_plano_anuncio: dados.id_plano_anuncio,
                ds_descricao_plano: dados.ds_descricao_plano,
                nr_quantidade_visualizacao: dados.nr_quantidade_visualizacao,
                valor_plano: dados.valor_plano,
            });
        }
    }, [dados]);

    const submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            const endpoint = 'adm-plano-anuncio';
            const method = dados ? "PUT" : "POST";
            const url = dados ? `${endpoint}/${data?.id_plano_anuncio}` : endpoint;
            const response = await axiosApi(
                {
                    method,
                    url,
                    data
                }
            );

            if (response.status === 201 && method === "POST") {
                const { dados } = response.data;
                success('Adicionado com sucesso!');
                dataArray.push(dados)
            } else {
                success('Atualizado com sucesso!');
            }
            closeModal();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <form className="flex flex-col " onSubmit={submitForm} >
            <label className="form-label-titulo my-3">Descrição do Plano</label>
            <input
                required
                type="text"
                name="ds_descricao_plano"
                onChange={onChangeEventInput}
                value={data?.ds_descricao_plano ?? ""}
                className="modal-input mb-3 p-1"
            />

            <label className="form-label-titulo my-3">Quantidade de Impressões</label>
            <input
                required
                type="text"
                name="nr_quantidade_visualizacao"
                onChange={onChangeEventInput}
                value={data?.nr_quantidade_visualizacao ?? ""}
                className="modal-input mb-3 p-1"
            />


            <label className="form-label-titulo my-3">Valor do Plano</label>
            <input
                required
                type="text"
                name="valor_plano"
                onChange={onChangeEventInput}
                value={data?.valor_plano ?? ""}
                className="modal-input mb-3 p-1"
            />

            <div className="flex flex-row mt-6 space-x-40">
                <label className="form-label-titulo">Status</label>
                <div>
                    <label className="form-label-titulo align-top">Ativo</label>
                    <ToggleSwitch
                        onChange={() => setIsChecked(data?.st_ativo)}
                        className="toggle"
                        checked={checkedValue === data?.st_ativo}
                        offColor={'#223066'}
                        onColor={'#223066'}
                        uncheckedIcon={false}
                        checkedIcon={false}
                    />
                </div>
            </div>

            <button className="btn">Salvar</button>
        </form>
    );
}
export default AnuncioPlano;