import React, { useMemo, useState } from "react";
import { usePagination, useTable, useFilters, useGlobalFilter } from 'react-table';
import { matchSorter } from 'match-sorter';
import './style.css';
import Seta from "../Seta";
import TableTitle from "../TableTitle";
import { SearchBlueIcon } from "src/assets/icons";
import GlobalFilter from './GlobalFilter';

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val

function CustomTable({ TableTitleComponent, columns, data }) {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const filterTypes = useMemo(
    () => ({

      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useFilters,
    useGlobalFilter,
    usePagination,
  );

  function generatePagesLinks() {
    const pages = [];
    const range = 3;
    const numberOfPages = pageCount;
    const currentPage = pageIndex;
    let toIndex = numberOfPages > (range * 2) ? currentPage + range : numberOfPages;
    let fromIndex = numberOfPages > (range * 2) ? currentPage - range : 1;

    if (toIndex > numberOfPages) {
      fromIndex = numberOfPages - (range * 2);
      toIndex = numberOfPages;
    } else if (fromIndex < 1) {
      fromIndex = 1;
      toIndex = 1 + (range * 2);
    }

    for (let index = fromIndex; index <= toIndex; index++) {
      pages.push(
        <span onClick={() => gotoPage(index - 1)} key={index} className={`cursor-pointer p-2 ${(index - 1 === pageIndex) ? 'font-bold text-blue-900' : 'font-normal text-gray-700'}`}>{index}</span>
      );
    }

    return pages;
  }

  return (
    <div className="w-full sombra p-5 flex flex-col justify-center">
      <div className="grid md:grid-cols-2 w-full justify-between items-center">
        <TableTitleComponent />
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <table {...getTableProps()} className="mt-5 admin-tbl-collapse table table-borderless">
        <thead className="admin-tbl-head">
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()} className="admin-tbl-linha">
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  className="admin-tbl-column font-bold"
                  scope="col">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}

        </thead>
        <tbody {...getTableBodyProps()} className="admin-tbl-body">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {
                  row.cells.map((cell) => {
                    return (
                      <td className="p-2" {...cell.getCellProps()}>{cell.render("Cell")} </td>
                    );
                  })
                }
              </tr>
            );
          })}

        </tbody>

      </table>
      <div className="pagination flex flex-col justify-center items-center mt-5">
        <div>
          <button className="font-bold text-blue-800" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <Seta paraEsquerda />
          </button>{" "}
          <span className="mx-2">
            <strong>
              {generatePagesLinks()}
            </strong>{" "}
          </span>
          <button className="font-bold text-blue-900" onClick={() => nextPage()} disabled={!canNextPage}>
            <Seta />
          </button>{" "}
        </div>
      </div>
    </div>
  );
};
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

filterGreaterThan.autoRemove = val => typeof val !== 'number';
export default React.memo(CustomTable);
