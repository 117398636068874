import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { UseTableCellProps } from 'react-table';
import { PlusBlueIcon } from 'src/assets/icons';
import ColoredContentButton from 'src/components/Basicos/ColoredContentButton';
import Table from 'src/components/Basicos/Table';

import ModalAdicionarEditar from 'src/components/Funcionalidades/Modal';
import ModalDelete from 'src/components/Funcionalidades/ModalDelete';
import { axiosApi } from 'src/services/axiosInstance';
import SubCategoriaForm from '../FormsAuxiliar/_SubCategoria';


type SubCategoriaProps = {
    id: number;
    nome?: string;
    ds_nome_imagem?: string;
    url?: string;
    qtd_servicoqtd_servico?: number;
    tb_categoria: {
        id_categoria?: number | string;
        nome?: string | number;
    }
}

const SubCategoria: React.FC = () => {
    const [data, setData] = useState<Array<SubCategoriaProps>>([]);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState<any>();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [index, setIndex] = useState<any>();

    const closeModal = (): void => {
        setOpen(false);
        setId(undefined);;
    }
    const openModal = (): void => {
        setOpen(true);
    }

    const closeModalDelete = () => setOpenModalDelete(false);

    const openModalDeleteFunc = (id: number): void => {
        setOpenModalDelete(true);
        setId(id);
        setIndex(index);
    }

    const getSubCategoriaPeloIndice = (index: number): SubCategoriaProps | undefined => {
        return data[index] ?? undefined;
    }

    const loadSubCategoria = async (): Promise<void> => {
        const response = await axiosApi.get('/adm-sub-categoria');
        setData(response.data.dados);

    }
    useEffect(() => {
        loadSubCategoria();
    }, [id]);

    const columns = [
        {
            Header: "Código",
            accessor: "id"
        },
        {
            Header: "Nome",
            accessor: "nome_sub"
        },
        {
            Header: "Serviços",
            accessor: "servicos"
        },
        {
            Header: "Categoria",
            accessor: "get_categoria.nome"
        },
        {
            Header: "",
            resizable: true,
            accessor: "acoes",
            Cell: ({ row }: UseTableCellProps<SubCategoriaProps>) => {
                return (
                    <Fragment>
                        <div className="flex flex-row p-0 justify-center">
                            <ColoredContentButton
                                onClick={() => {
                                    getSubCategoriaPeloIndice(row.index);
                                    setId(row.index);
                                    setOpen(true);
                                }}
                            >
                                <FiEdit />
                            </ColoredContentButton>
                            <ColoredContentButton
                                name="delete"
                                onClick={() => {
                                    openModalDeleteFunc(row.original.id);
                                    getSubCategoriaPeloIndice(row.index);
                                    setIndex(row.index);
                                }}
                            >
                                <FiTrash2 />
                            </ColoredContentButton>
                        </div>
                    </Fragment>
                );
            }
        }
    ]

    return (
        <div className="mt-8 mb-4 ml-8 home-box sombra p-3 grid sm:grid-cols-1 md:grid-cols-6 lg:grid-cols-12">
            <div className="flex">
                <span className="font-bold mt-1 mr-3 lg:col-start-1 lg:col-span-1 mb-6 mr-2 ml-4">Subcategorias</span>
                <span
                    onClick={openModal}
                    style={{
                        cursor: 'pointer'
                    }}
                >
                    <span className="cursor-pointer">
                        <PlusBlueIcon />
                    </span>
                </span>
            </div>
            <div className="grid lg:col-span-12 lg:col-start-1">
                <Table columns={columns} data={data} />
            </div>
            <ModalAdicionarEditar
                open={open}
                closeModal={closeModal}
                titulo="SubCategoria"
                dados={id ?? undefined}
            >
                <SubCategoriaForm
                    dados={id !== undefined ? getSubCategoriaPeloIndice(id) : undefined}
                    closeModal={closeModal}
                    dataArray={data}
                />
            </ModalAdicionarEditar>
            <ModalDelete
                open={openModalDelete}
                closeModal={closeModalDelete}
                titulo={'SubCategoria'}
                rota={'/crud/SubCategoria'}
                id={id}
                index={index !== undefined ? getSubCategoriaPeloIndice(index) : undefined}
                data={data}
            />
        </div>
    );
}

export default SubCategoria;