import React, { SyntheticEvent } from 'react';
import Popup from 'reactjs-popup';
import { success as successToast, error as errorToast } from 'src/components/Basicos/funcoes';
import { axiosApi } from 'src/services/axiosInstance';

type Props = {
    open: boolean;
    closeModal: any;
    titulo: string;
    id: React.ReactNode;
    rota: string;
    index?: any;
    data?: any;
}

const deleteRow: Function = async (rota: string, id: number) => {
    console.log(`${rota}/${id}`)
    return await axiosApi.delete(`${rota}/${id}`);
}

const ModalDelete: React.FC<Props> = ({ open, closeModal, titulo, id, rota }: Props): JSX.Element => {
    const handleClick = async (event: SyntheticEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();
        try {
            await deleteRow(rota, id);
            closeModal();
            successToast('Deletado com sucesso!');
        } catch (err) {
            closeModal();
            errorToast(err?.response?.data?.mensagem || 'Erro ao tentar deletar.');
            console.log(err.response);
        }
    };

    return (
        <Popup
            open={open}
            closeOnDocumentClick
            onClose={closeModal}>
            {(closeModal: any) => (
                <div className="modal">
                    <div className="topo-modal-adicionar-editar">
                        <div className="icone-voltar-modal" onClick={closeModal}>
                            <img src={require('../../../assets/icones/seta-esquerda.png')} />
                        </div>
                        <div className="titulo-modal-adicionar-voltar">
                            <h1>{titulo}</h1>
                        </div>
                    </div>
                    <div>
                        <p className="my-4">Deseja deletar esse item?</p>
                        <button
                            className="btn"
                            onClick={handleClick}>Deletar</button>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default ModalDelete;