import { useEffect, useMemo, useState } from "react";
import { ReactTableColumn } from "src/@types";

/**
 * React Hook para controlar alterações numa tabela.
 * @param columns 
 * @param callbackToReload 
 */
export const useReloadTable = <T extends object>(columns: Array<ReactTableColumn>, callbackToReload: (setTableData: React.Dispatch<React.SetStateAction<T[]>>) => void) => {

    const [tableData, setTableData] = useState<Array<T>>([]);

    useEffect(() => {
        callbackToReload(setTableData);
    }, []);

    /**
     * Colunas da tabela.
     */
    const tableColumns = useMemo(() => columns, [tableData]);

    /**
     * Retorna o item de uma tabela.
     */
    function getItem<T>(value: string | number | undefined, tableData: T[], name: string) {
        return tableData.filter(el => el[name] === value)[0];
    }

    return {
        tableData,
        tableColumns,
        setTableData,
        getItem,
    };

}