import React, { Fragment } from "react";
import "./style.css";
// import { BsChevronDown } from "react-icons/bs";
// import ButtonFormSubmit from "../Inputs/buttonSubmit";
// import { BiCheck } from "react-icons/bi";
import { NavLink } from 'react-router-dom';
import { FaBullhorn } from 'react-icons/fa';
// import { axiosApi } from "src/services/axiosInstances";
// import InputMask from "react-input-mask";
// import { toast } from "react-toastify";
// import { AiOutlineCheckCircle } from "react-icons/ai";

type Props = {
    idCirurgia?: number;
    accordionTitle?: string;
    actAccordion?: boolean;
    toggleAccordion?: any;
    confirmacao_hora?: string;
    confirmacao_data?: string;
    etapa_id?: number;
    data_edit?: string;
    materiais?: Array<Material>;
    titulo?: string;
};

// type Form = {
//   etapa_id?: number;
//   confirmacao_hora?: string;
//   confirmacao_data?: string;
// };

type Material = {
    fornecedor_id: number;
    descricao: string;
};

export default function MenuColapse({
    idCirurgia,
    accordionTitle,
    toggleAccordion,
    confirmacao_hora,
    confirmacao_data,
    etapa_id = 3,
    data_edit,
    materiais,
    actAccordion,
    titulo,
}: Props): JSX.Element {
    const active = !actAccordion ? "active-accordion" : " ";
    const height = !actAccordion ? "0px" : "100%";
    const display = !actAccordion ? "none" : "block";
    // const icon = !actAccordion ? "accordion-icon" : "accordion-icon-rotate";
    // const title = !actAccordion ? "black" : "#2460d7";
    // const [clicado, setClicado] = useState<boolean>(false)

    return (
        <Fragment>
            <div className={`accordion-section py-3`}>
                <div
                    className={`accordion-btn text-left ${active}`}
                    onClick={toggleAccordion}
                >
                    <div className="cursor-pointer menuColapseOpcao">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FaBullhorn className="m-auto" style={{ width: '24px', height: '24px' }} />
                        </div>
                        <p>{titulo}</p>
                    </div>
                </div>
                <div
                    className="accordion-content"
                    style={{ height: `${height}`, display: `${display}` }}
                >

                    <hr style={{ width: '4rem' }} />

                    <NavLink exact activeClassName='is-active' to={'/planos/anuncio'}>
                        <p>Planos</p>
                    </NavLink>
                    <NavLink exact activeClassName='is-active' to={'/anunciantes'}>
                        <p>Anunciantes</p>
                    </NavLink>
                    <NavLink exact activeClassName='is-active' to={'/anuncios'}>
                        <p>Anúncios</p>
                    </NavLink>
                </div>
            </div>
        </Fragment>
    );
}
