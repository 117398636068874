import React, { Fragment } from 'react'
import {CustomTextAreaProps} from 'src/@types';

function CustomTextArea({ label, rows, cols, name, onChange, value }: CustomTextAreaProps) {

    return (
        <Fragment>
            <label className="text-left my-3">{label}</label>
            <textarea
                rows={rows || 3}
                cols={cols || 25}
                name={name}
                onChange={onChange}
                value={value}
                className="modal-input mb-3 p-1"
            >
            </textarea>
        </Fragment>
    );
}

export default CustomTextArea;
