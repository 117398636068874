import React, { useEffect, useState } from 'react';
import TableToExcel from "@linways/table-to-excel";
import { FaFileCsv } from "react-icons/fa";

const ButtonExportCSV = ({
    isDisabled,
    data
}) => {

    const [tableExcel, setTableExcel] = useState();
    const [buttonExport, setButtonExport] = useState();
    
    let ButtonExport;
    let table;

    useEffect(() => {
      ButtonExport = document.getElementById("button-excel");
      table = document.getElementById("tableExportCSV");
      setButtonExport(prev => ButtonExport);
      setTableExcel(prev => table)
    }, []);

    useEffect(() => {
        ButtonExport.addEventListener("click", e => {
            const date = new Date();
            TableToExcel.convert(table, {
                name: `Relatório Categorias e SubCategorias IServ-${date}.xlsx`,
                sheet:{
                    name: `Relatório Categorias e SubCategorias`
                }
            });
        });
    }, [])

    return(
        <React.Fragment>

        <table id="tableExportCSV"  style={{display:'none'}}>
        <thead>
            <tr data-height="42">
                <th colSpan="16" data-f-sz="16"  role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="223066" data-f-color="FFFFFF" data-f-bold={true} data-b-a-s="medium"	data-b-a-c="FFFFFF">Planilha de Categorias e SubCategorias ISERV</th>
            </tr>
        </thead>
            <tbody>
                {
                    data?.map((dados, index) => {
                        return (
                            <React.Fragment>    
                                <tr key={index}>
                                    <th colSpan="2" role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="223066" data-f-color="FFFFFF" data-f-bold={true} data-b-a-s="medium"	data-b-a-c="FFFFFF">Código Categoria</th>
                                    <th colSpan="5" role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="223066" data-f-color="FFFFFF" data-f-bold={true} data-b-a-s="medium"	data-b-a-c="FFFFFF">Nome da Categoria</th>
                                    <th colSpan="3" role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="223066" data-f-color="FFFFFF" data-f-bold={true} data-b-a-s="medium"	data-b-a-c="FFFFFF">Código SubCategoria</th>
                                    <th colSpan="6" role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="223066" data-f-color="FFFFFF" data-f-bold={true} data-b-a-s="medium"	data-b-a-c="FFFFFF">Nome da SubCategoria</th>
                                </tr>
                                {
                                    dados?.sub_categorias.length == 0 ?
                                    (
                                        <React.Fragment>
                                            <tr key={index}>
                                                <td colSpan="2" role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF" data-f-color="828282" data-b-a-s="medium" data-b-a-c="ececec">{dados?.id}</td>
                                                <td colSpan="5" role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF" data-f-color="828282" data-b-a-s="medium"	data-b-a-c="ececec">{dados?.nome}</td>
                                            </tr>
                                        </React.Fragment>
                                    ) : (
                                        dados?.sub_categorias?.map((dados_sub, indexSub) => {
                                            return(
                                                <React.Fragment>
                                                    <tr key={indexSub}>
                                                        <td colSpan="2" role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF" data-f-color="828282" data-b-a-s="medium" data-b-a-c="ececec">{dados_sub?.id_categoria}</td>
                                                        <td colSpan="5" role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF" data-f-color="828282" data-b-a-s="medium"	data-b-a-c="ececec">{dados?.nome}</td>
                                                        <td colSpan="3" role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF" data-f-color="828282" data-b-a-s="medium"	data-b-a-c="ececec">{dados_sub?.id}</td>
                                                        <td colSpan="6" role="columnheader" data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF" data-f-color="828282" data-b-a-s="medium"	data-b-a-c="ececec">{dados_sub?.nome_sub}</td>
                                                    </tr>
    
                                                </React.Fragment>
                                            )
                                        })
                                    )
                                }

                            </React.Fragment>
                        );
                    })
                }
            </tbody>
        </table>

            <button disabled={isDisabled} id="button-excel"
                className="shadow py-1 rounded-full p-2 main-mes flex items-center justify-around" 
                style={{outline:'none', width:'9rem', padding:'0.5rem', color: isDisabled ? '#ccc' : '#ffffff', background: isDisabled ? '#44425a' : '#223066'}}>
                <FaFileCsv fontSize={'1.2rem'}/>
                Download
            </button>
        </React.Fragment>

    );
}

export default ButtonExportCSV;