import React from 'react';
import { useModalContext } from 'src/context/modal';
import axiosApi from 'src/services/axiosInstance';
import { error as errorToast, success as successToast, jsonToFormData } from '../components/Basicos/funcoes';

export default function useApi({ endpoint, isMultipart = false }) {

    const modal = useModalContext();

    /**
     * Envia uma requisição para deletar um recurso.
     */
    async function destroy(event, id) {

        event.preventDefault();

        try {
            await axiosApi.delete(`${endpoint}/${id}`);
            modal.close();
            successToast('Deletado com sucesso!');
        } catch (err) {
            modal.close();
            errorToast(err?.response?.data?.mensagem || 'Erro ao tentar deletar.');
            console.log(err.response);
        }
    };

    /**
     * Envia uma requisição para criar um recurso.
     */
    async function create(event, data) {

        event.preventDefault();

        try {
            const response = await axiosApi(
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': isMultipart ? 'multipart/form-data;' : 'application/json'
                    },
                    url: endpoint,
                    data: isMultipart ? jsonToFormData(data, 'POST') : data
                }
            );
            successToast('Adicionado com sucesso!');
            modal.close();

        } catch (error) {
            errorToast(error.response?.data?.mensagem)
        }
    }

    /**
     * Envia uma requisição para atualizar um recurso.
     */
    async function update(event, data, id, _endpoint = '') {

        event.preventDefault();

        try {
            const httpMethod = ((!id || !!isMultipart) && (!_endpoint)) ? 'POST' : 'PUT';

            const response = await axiosApi(
                {
                    method: httpMethod,
                    headers: {
                        'Content-Type': isMultipart ? 'multipart/form-data;' : 'application/json'
                    },
                    url: (_endpoint !== '') ? _endpoint : (!!id ? `${endpoint}/${id}` : endpoint),
                    data: isMultipart ? jsonToFormData(data, httpMethod) : data
                }
            );

            successToast('Atualizado com sucesso!');
            modal.close();

        } catch (error) {
            errorToast(error.response?.data?.mensagem)
        }
    }

    return { create, update, destroy };
}