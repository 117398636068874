import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom'
import { FaQuestion, FaRegUser, FaThLarge } from 'react-icons/fa';
import { AiOutlineHome, AiOutlineSearch } from 'react-icons/ai';
import { RiNewspaperLine } from 'react-icons/ri';
// import { FiLogOut } from 'react-icons/fi';
import './style.css'
import MenuColapse from 'src/components/Basicos/MenuColapse';
import { FiLogOut } from 'react-icons/fi';

function Sidebar(): JSX.Element {

    const [activeAccordion, setActiveAccordion] = useState(false);
    const toggleActiveAccordion = () => {
        setActiveAccordion(!activeAccordion);
    };


    return (
        <div
            className="z-40 
            bg-white side-menu align-content-middle 
            shadow-xl top-0 fixed 
            min-h-screen
            flex flex-col items-center"
        >
            <div className='align-content-middle my-4'>
                <img src={require('../../../assets/main/icone-main.svg')} width='60' height='60' alt='logo iserv' />
            </div>

            <nav id="sidebar-menu">
                <NavLink exact activeClassName='is-active' to={'/'}>
                    <AiOutlineHome className="m-auto" />
                    <span>Início</span>
                </NavLink>

                <NavLink exact activeClassName='is-active' to={'/categorias'} >
                    <FaThLarge className="m-auto" />
                    <p>Categorias e</p>
                    <p>SubCategorias</p>
                </NavLink>

                <NavLink exact activeClassName='is-active' to={'/planos'} >
                    <RiNewspaperLine className="m-auto" />
                    <p>Planos</p>
                </NavLink>
                <NavLink activeClassName='is-active' to={'/usuarios'} >
                    <FaRegUser className="m-auto" />
                    <p>Usuários</p>
                </NavLink>

                <MenuColapse
                    actAccordion={activeAccordion}
                    toggleAccordion={() => toggleActiveAccordion()}
                    titulo="Anúncios"
                />

                {/* <NavLink exact activeClassName='is-active' to={'/anuncios'}>
                    <FaBullhorn className="m-auto" />
                    <p>Anúncios</p>
                </NavLink> */}

                <NavLink exact activeClassName='is-active' to={'/duvidas'}>
                    <FaQuestion className="m-auto" />
                    <p>Dúvidas</p>
                    <p>Frequentes</p>
                </NavLink>
                <NavLink exact activeClassName='is-active' to={'/consultas'}>
                    <AiOutlineSearch className="m-auto" />
                    <p>Consultas</p>
                </NavLink>

                <NavLink exact to={'/auth/logout'}>
                    <FiLogOut className="m-auto" />
                    <p>Sair</p>
                </NavLink>

            </nav>
        </div>
    );
}

export default Sidebar;