import React from 'react';
import { ISelect } from 'src/@types/components';

const Select: React.FC<ISelect> = ({ className, value, options, name, onChange }) => {

    return (
        <select value={value} onChange={onChange} className={className || "modal-input"} name={name} >
            {
                options.map((option) => (
                    <option
                        value={option.value}
                        key={option.value}
                    >{option.label}</option>
                ))
            }
        </select>
    );
}

export default Select;