import React from 'react'
import { SvgIconProps } from 'src/@types';

export function PlusBlueIcon({ className }: SvgIconProps) {
    return (
        <svg className={className} width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0027 28.5455C21.7347 28.5455 28.0027 22.2775 28.0027 14.5455C28.0027 6.81348 21.7347 0.545471 14.0027 0.545471C6.27076 0.545471 0.00274658 6.81348 0.00274658 14.5455C0.00274658 22.2775 6.27076 28.5455 14.0027 28.5455Z" fill="#223066" />
            <path d="M14.0027 8.9455V20.1455" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.40277 14.5455H19.6028" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export function SearchBlueIcon({ className }: SvgIconProps) {
    return (
        <svg className={className} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.8596 17.392L13.2123 12.819C14.4293 11.4968 15.1771 9.7481 15.1771 7.8239C15.1765 3.70767 11.786 0.371277 7.60343 0.371277C3.42082 0.371277 0.0303955 3.70767 0.0303955 7.8239C0.0303955 11.9401 3.42082 15.2765 7.60343 15.2765C9.41061 15.2765 11.0681 14.6514 12.3701 13.6122L17.0354 18.2033C17.2627 18.4273 17.6318 18.4273 17.8591 18.2033C18.087 17.9794 18.087 17.6159 17.8596 17.392ZM7.60343 14.1299C4.06448 14.1299 1.19561 11.3066 1.19561 7.8239C1.19561 4.34119 4.06448 1.51791 7.60343 1.51791C11.1424 1.51791 14.0112 4.34119 14.0112 7.8239C14.0112 11.3066 11.1424 14.1299 7.60343 14.1299Z" fill="#223066" />
        </svg>
    );
}