import React from 'react';
import Popup from 'reactjs-popup';
import "./style.css"

type Props = {
  open: boolean;
  closeModal: any;
  titulo: string;
  children: React.ReactNode;
  dados: number;
}

/**
 * @deprecated
 */
function ModalAdicionarEditar
  ({ open, closeModal, titulo, children, dados }: Props): JSX.Element {
  return (
    <div>
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}>
        {(closeModal: any) => (
          <div className="modal">

            <div className="topo-modal-adicionar-editar">
              <div className="icone-voltar-modal" onClick={closeModal}>
                <img src={require('../../../assets/icones/seta-esquerda.png')} />
              </div>
              <div className="titulo-modal-adicionar-voltar">
                <h1>{ dados ? `${'Atualizar'} ${titulo}` : `${'Nova'} ${titulo}` }</h1>
              </div>
            </div>

            <div className="conteudo-modal-adicionari-editar">
              {children}
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default ModalAdicionarEditar;