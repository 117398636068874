import React, { Fragment, useState } from 'react';
import axiosApi from 'src/services/axiosInstance';
import { UseTableCellProps } from 'react-table';
import { useReloadTable } from 'src/hooks/useReloadTable';
import { FuncionalidadeDosPlanosForm } from './Form';
import { TipoDeFuncionalidadeProps, ReactTableColumn, FuncionalidadePlanoProps } from 'src/@types';
import TableActionsMenu from 'src/components/Basicos/TableActionsMenu';
import CustomTableWithTitle from 'src/components/Basicos/CustomTableWithTitle';
import useRowId from 'src/hooks/useRowId';
import ModalGroup from 'src/components/Basicos/ModalGroup';
import useModal from 'src/hooks/useModal';

function FuncionalidadesDosPlanos(): JSX.Element {

    const { openCreateOrUpdateModal, setOpenCreateOrUpdateModal, openDeleteModal, setOpenDeleteModal } = useModal(false);
    const { rowId, setRowId, handleEdit, handleDelete, getResourceById } = useRowId(setOpenCreateOrUpdateModal, setOpenDeleteModal);

    /**
     * Items do cabeçalho da tabela.
     */
    const reactTableColumns: ReactTableColumn[] = [
        { Header: "Código", accessor: "id" },
        { Header: "Nome", accessor: "nome_funcionalidade" },
        { Header: "Tipo", accessor: "ds_funcionalidade" },
        {
            Header: "Status",
            Cell: ({ row }: UseTableCellProps<FuncionalidadePlanoProps>) => (
                <div className="">
                    {row.original.st_ativo === true ? 'Ativo' : 'Inativo'}
                </div>
            ),
            accessor: "st_ativo"
        },
        {
            Header: '',
            accessor: 'acoes',
            Cell: ({ row }: UseTableCellProps<FuncionalidadePlanoProps>) => (
                <TableActionsMenu
                    onClickToDelete={() => handleDelete(row.original.id)}
                    onClickToEdit={() => handleEdit(row.original.id)}
                />
            )
        }
    ];

    const [
        tiposDeFuncionalidades,
        setTiposDeFuncionalidades
    ] = useState<TipoDeFuncionalidadeProps[]>([] as TipoDeFuncionalidadeProps[]);

    /**
     * Função responsável por buscar os dados para listar a tabela
     * e atualizá-los.
     */
    async function reloadTableData() {
        const response = await axiosApi.get('/crud/FuncionalidadePlanoAssinatura?per_page=1000');
        const responseTipoFuncionalidades = await axiosApi.get('adm-tipo-funcionalidade');
        setTableData(response.data.data);
        setTiposDeFuncionalidades(responseTipoFuncionalidades.data.dados);
    }

    /**
     * Atualiza os dados da tabela conforme hajam mudanças em tableData.
     */
    const {
        tableData,
        tableColumns,
        setTableData
    } = useReloadTable<FuncionalidadePlanoProps>(reactTableColumns, reloadTableData);

    console.log('tiposDeFuncionalidades', tiposDeFuncionalidades)
    return (
        <Fragment>
            <CustomTableWithTitle
                tableTitle="Funcionalidade dos Planos"
                tableData={tableData}
                tableColumns={tableColumns}
                onClickToCreate={() => { setOpenCreateOrUpdateModal(true); setRowId(undefined); }}
            />
            <ModalGroup
                title='Funcionalidade'
                rowId={rowId}
                openCreateOrUpdateModal={openCreateOrUpdateModal}
                openDeleteModal={openDeleteModal}
                setOpenCreateOrUpdateModal={setOpenCreateOrUpdateModal}
                setOpenDeleteModal={setOpenDeleteModal}
                endpoint={'adm-funcionalidade-plano'}
                reloadTable={reloadTableData}
            >
                <FuncionalidadeDosPlanosForm
                    rowId={rowId}
                    formValues={!!rowId ? getResourceById(rowId, tableData, 'id') : undefined}
                    tiposDeFuncionalidades={tiposDeFuncionalidades}
                    reloadFunction={reloadTableData}
                    setOpen={setOpenCreateOrUpdateModal}
                />
            </ModalGroup>
        </Fragment>
    );
}

export default FuncionalidadesDosPlanos;