import { faArrowUp, faGift } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { axiosApi } from 'src/services/axiosInstance';
interface IUserPropsCard {
    numero: number;
}
const CardServico: React.FunctionComponent<IUserPropsCard> = ({ }) => {

    const [quantidadeDeServicos, setQuantidadeDeServicos] = useState(0)
    const [percentualDeServicosNovos, setPercentualDeServicosNovos] = useState(0)
    const [houveCrescimento, setHouveCrescimento] = useState(false)

    const loadDadosUsuarios = async (): Promise<void> => {
        const response = await axiosApi.get('adm-controle-servicos-prestados');
        setQuantidadeDeServicos(response.data.qtd_servicos)
        setPercentualDeServicosNovos(response.data.percentual_servicos_novos)
        setHouveCrescimento(response.data.crescimento)
    }

    useEffect(() => {
        loadDadosUsuarios();
    }, []);
    
    return (
        <div className="grid sm:grid-cols-12 md:grid-cols-6 lg:grid-cols-1 rounded-lg shadow shadow-md mt-8 p-4 ml-3 w-full">
            <div className="flex space-x-4">

                <span className="card-usuario-titulo">Serviços</span>
                <FontAwesomeIcon icon={faGift} />
            </div>
            <p className="card-usuario-numero">{quantidadeDeServicos}</p>
            <p className="card-usuario-info">
                {houveCrescimento && <FontAwesomeIcon icon={faArrowUp} />}&nbsp;
                {percentualDeServicosNovos} desde o mes passado</p>
        </div>
    )
}

export default CardServico;