import React, {memo} from 'react';
import './style.css';

import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import ButtonExportCSV from '../ButtonExportCSV';



export type ClassicProps = {
  className?: string;
  id?: string;
  name?: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  border?: string;
  maxLength?: number;
  pattern?: string;
  title?: string;
  min?: string | number;
  max?: string | number;
  value?: any;
  defaultValue?: string;
  children?: Array<JSX.Element> | JSX.Element;
  content?: string;
  style?: any;
  readOnly?: boolean;
};

export type ModalProps = {
  open?: any;
  close?: any;
  toggle?: any;
};

export type AccordionProps = {
  title?: string;
  key?: string | number;
  uuid?: string;
  pathname: string;
  getDados: (pathname: string) => void;
};

interface AccordionConsultasProps{
    position: number;
    isDisabled?: boolean;
    data: any
}
const Accordion : React.FC <AccordionConsultasProps & AccordionProps & ClassicProps & ModalProps> = ({
    key,
    uuid,
    title,
    children,
    toggle,
    getDados,
    pathname,
    position,
    isDisabled,
    data
}) => {

    return(
        <div style={{padding: '0 2rem'}}>
            <AccordionItem key={key} className="py-3" uuid={uuid} onClick={() => getDados(pathname)} > 
                <AccordionItemHeading className="bg-blue-iserv text-white font-bold py-3 rounded-lg shadow-lg" style={{padding:'0.75rem 2rem', color:"white"}}>
                    <AccordionItemButton className="outline-none cursor">
                        <div className="flex justify-between px-8 h-full items-center content-center outline-none">
                            <span className="text-2xl">{title}</span>
                            <ButtonExportCSV
                                isDisabled={isDisabled}
                                data={data}
                            />
                        </div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={`bg-white rounded-t-none rounded-b-lg shadow-lg w-full h-hull`} style={{marginTop:"-4px"}}>
                    {children}
                </AccordionItemPanel>
            </AccordionItem>
        </div>
    );
}

export default memo(Accordion);