import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import Table from 'src/components/Basicos/Table';
import { UseTableCellProps } from 'react-table';
import ModalAdicionarEditar from 'src/components/Funcionalidades/Modal';
import { axiosApi } from 'src/services/axiosInstance';
import CategoriaForm from '../FormsAuxiliar/_Categoria';
import ModalDelete from 'src/components/Funcionalidades/ModalDelete';
import ColoredContentButton from 'src/components/Basicos/ColoredContentButton';


type UsuarioProps = {
   id: number;
   nr_cpf_cnpj?: string;
   ds_nome_imagem?: string;
   nome_razao_social?: string;
   nome_usuario?: string;
   email?: string;
   ds_facebook?: string;
}

const Usuario: React.FC = () => {

   const [data, setData] = useState<Array<UsuarioProps>>([]);
   const [open, setOpen] = useState(false);
   const [openModalDelete, setOpenModalDelete] = useState(false);
   const [id, setId] = useState(0);

   const closeModal = (): void => {
      setOpen(false);
      setId(0);
   }

   const closeModalDelete = () => setOpenModalDelete(false);

   const openModalDeleteFunc = (id: number): void => {
      setOpenModalDelete(true);
      setId(id);
   }

   const openModal = (): void => {
      setOpen(true);
   }

   const getCategoriaPeloIndice = (index: number): UsuarioProps | undefined => {
      return data[index] ?? undefined;
   }

   const loadUsuarios = async (): Promise<void> => {
      const response = await axiosApi.get('crud/Usuario');
      setData(response.data.data);
   }

   useEffect(() => {
      loadUsuarios();
   }, []);

   const columns = [
      {
         Header: "Código",
         accessor: "id"
      },
      {
         Header: "Nome",
         accessor: "nome_razao_social"
      },
      {
         Header: "Nome de Usuário",
         accessor: "nome_usuario"
      },
      {
         Header: "Documento",
         accessor: "nr_cpf_cnpj"
      },
      {
         Header: "Email",
         accessor: "email"
      },
      {
         Header: "Plano",
         accessor: "plano_cliente.valor_plano_assinatura.plano_assinatura.ds_nome_plano_assinatura"
      },
      {
         Header: "",
         resizable: true,
         accessor: "acoes",
         Cell: ({ row }: UseTableCellProps<UsuarioProps>) => {
            return (
               <Fragment>
                  <div className="flex flex-row p-0 justify-center">
                     <ColoredContentButton className="btn-table "
                        onClick={() => {
                           getCategoriaPeloIndice(row.index);
                           setOpen(true);
                           setId(row.index);
                        }}
                     >
                        <FiEdit />
                     </ColoredContentButton>
                     <ColoredContentButton
                        className="btn-table"
                        name="delete"
                        onClick={() => openModalDeleteFunc(row.original.id)}
                     >
                        <FiTrash2 />
                     </ColoredContentButton>
                  </div>
               </Fragment>
            );
         }
      }
   ]

   return (
      <Fragment>
         <div className="mt-24 ml-8 home-box sombra p-3 grid sm:grid-cols-1 md:grid-cols-6 lg:grid-cols-12">
            <div className="flex">
               <span className="main-home-titulo lg:col-start-1 lg:col-span-1 mb-6 mr-2">Usuários</span>
            </div>
            <div className="grid lg:col-span-12 lg:col-start-1">
               <Table columns={columns} data={data} />
            </div>
            <ModalAdicionarEditar
               open={open}
               closeModal={closeModal}
               titulo="Categoria"
               dados={id ?? undefined}
            >
               <CategoriaForm
                  dados={id ? getCategoriaPeloIndice(id) : undefined}
                  closeModal={closeModal}
                  dataArray={data}
               />
            </ModalAdicionarEditar>
            <ModalDelete
               open={openModalDelete}
               closeModal={closeModalDelete}
               rota={'/usuario-delete'}
               titulo={'Usuário'}
               id={id}
               index={id !== undefined ? getCategoriaPeloIndice(id) : undefined}
               data={data}
            />
         </div>
      </Fragment>
   );
}

export default Usuario;