import React, { memo, useEffect, useState } from "react";
import Accordion from "src/components/Basicos/Accordion";
import { axiosApi } from "../../../services/axiosInstance";


const ConsultasCategorias : React.FC = () =>{
    const [loaderTable, setLoaderTable] = useState<boolean>(true);
    const [dadosTabela, setDadosTabela] = useState<Array<any>>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [id, setId] = useState<number | undefined>();

    const getDados = async () => {
        try {
            setLoaderTable(true);
        if (dadosTabela.length === 0) {
             const response = await axiosApi.get('adm-categoria');
            setDadosTabela(response.data.dados);
        }
        } catch (error) {
            console.log(error);
        } finally {
            setLoaderTable(false);
        }
    };

    useEffect(() => {
        getDados();
    }, [])

    return(
        <React.Fragment>
            <Accordion 
                position={0}
                title="Categorias e SubCategorias"
                pathname="adm-categoria"
                getDados={getDados}
                toggle={() => setIsOpen(false)}
                isDisabled={loaderTable}
                data={dadosTabela}
            >
                <div style={{backgroundColor:'#ffffff', padding:'1rem', display: 'flex', color:'#223066', fontWeight:'bold'}}>Faça Dowload dos dados das Categorias e SubCategorias.</div>
            </Accordion>
        </React.Fragment>
    );
}

export default memo(ConsultasCategorias);