import React, { Fragment, PureComponent } from 'react';
import { FaSketch } from 'react-icons/fa';
import { BarChart, Bar, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import './style.css';
const data = [
    {
        name: '1', uv: 150, pv: 2400, amt: 2400,
    },
];

export default class BarGraphCardPremium extends PureComponent {
    render() {
        return (
            <div id="bar-graph-premium">
                <BarChart width={100} height={100} data={data} >
                    <XAxis dataKey="name" stroke="#00FFCC" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="uv" fill="#00FFCC" barSize={30} width="500" />
                </BarChart>
                <button className="btn-bar-opacidade">
                    <FaSketch stroke="#fff" className="mr-2" /> PREMIUM</button>
            </div>
        );
    }
}
