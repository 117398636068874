import React, { Fragment, useEffect, useState } from 'react';
// import SelectForm from 'src/components/Basicos/Form/SelectForm';
import { success } from 'src/components/Basicos/funcoes';
// import ToggleSwitch from 'react-switch';
import { axiosApi } from 'src/services/axiosInstance';
import './style.css';

interface AnuncianteProps {
    id_plano_por_anuncio: number;
    ds_titulo_anuncio?: string;
    tb_plano_anuncio: {
        id_plano_anuncio?: number;
        ds_descricao_plano?: string;
    };
    tb_anuncio: {
        id_anuncio?: number;
        tb_anunciante: {
            id_anunciante?: number;
            data_cadastro?: string;
            nome_razao_social?: string;
            nr_cpf_cnpj?: string;
            nr_telefone?: string;
            nr_whatsapp?: string;
            ds_email?: string;
            nm_pessoa_contato?: string;
            nr_cep?: string;
            ds_logradouro?: string;
            ds_numero_endereco?: string;
            ds_complemento?: string;
            ds_bairro?: string;
            ds_cidade?: string;
            ds_estado?: string;
        };
    };
    valor_plano_anuncio?: number;
    // st_ativo: boolean;
}

type Props = {
    dados?: AnuncianteProps;
    closeModal: any;
    dataArray: any;
}

// type FuncionalidadePlano = {
//     id_tipo_funcionalidade?: number | undefined;
//     descricao?: string | undefined;
//     value?: any | undefined;
// }
const Anunciante: React.FC<Props> = ({ dados, closeModal, dataArray }: Props) => {
    const [data, setData] = useState<AnuncianteProps>();


    // const [checkedValue, setIsChecked] = useState<boolean | undefined>(false);

    const onChangeEventInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { name, value, } = event.target;
        setData((prevState: any) => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        if (dados) {
            setData({
                ...dados,
            });
        }
    }, [dados]);

    const submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            const endpoint = 'adm-plano-anuncio';
            const method = dados ? "PUT" : "POST";
            const url = dados ? `${endpoint}/${data?.id_plano_por_anuncio}` : endpoint;
            const response = await axiosApi(
                {
                    method,
                    url,
                    data
                }
            );

            if (response.status === 201 && method === "POST") {
                const { dados } = response.data;
                success('Adicionado com sucesso!');
                dataArray.push(dados)
            } else {
                success('Atualizado com sucesso!');
            }
            closeModal();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <form className="flex flex-col " onSubmit={submitForm} >
            <div className="d-flex">
                <FormaLabelInput
                    titulo="Código"
                    nome_do_campo="id"
                    valor_campo={data?.id_plano_por_anuncio ?? ""}
                    tipo_campo="text"
                    width="3rem"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Nome"
                    nome_do_campo="nome_do_campo_da_model"
                    valor_campo={data?.id_plano_por_anuncio ?? ""}
                    tipo_campo="text"
                    required
                    width="40rem"
                    funcao={onChangeEventInput}
                />
            </div>

            <div className="">
                <FormaLabelInput
                    titulo="Número do Documento"
                    nome_do_campo="nr_cpf_cnpj"
                    valor_campo={data?.tb_anuncio.tb_anunciante.nr_cpf_cnpj ?? ""}
                    tipo_campo="text"
                    required
                    funcao={onChangeEventInput}
                />
                <FormaLabelInput
                    titulo="Nome da Pessoa de Contato"
                    nome_do_campo="nm_pessoa_contato"
                    valor_campo={data?.tb_anuncio.tb_anunciante.nm_pessoa_contato ?? ""}
                    tipo_campo="text"
                    required
                    funcao={onChangeEventInput}
                />
            </div>

            <button className="btn">Salvar</button>
        </form>
    );
}


interface CamposForm {
    titulo?: string,
    nome_do_campo?: string,
    valor_campo?: number | string,
    tipo_campo?: string,
    required?: boolean,
    funcao?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    width?: string,
    height?: string,
    marginLeft?: string,
    marginRigth?: string,
    displayBlock?: string,
}

const FormaLabelInput: React.FC<CamposForm> = (props) => {
    return (
        <Fragment>
            <label className="form-label-titulo my-3" style={{ display: props.displayBlock }}>{props.titulo}:</label>
            <input
                required={props.required ? true : false}
                type={props.tipo_campo}
                name={props.nome_do_campo}
                onChange={props.funcao}
                value={props.valor_campo ?? ""}
                className="modal-input mb-3 p-1"
                style={{ width: props.width, height: props.height, marginLeft: props.marginLeft, marginRight: props.marginRigth }}
            />
        </Fragment>
    );
};

export default Anunciante;