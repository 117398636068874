import React, { Fragment, useEffect, useState } from 'react';
import axiosApi from 'src/services/axiosInstance';
import { UseTableCellProps } from 'react-table';
import { useReloadTable } from 'src/hooks/useReloadTable';
import { ReactTableColumn } from 'src/@types';
import TableActionsMenu from 'src/components/Basicos/TableActionsMenu';
import CustomTableWithTitle from 'src/components/Basicos/CustomTableWithTitle';
import { useModalContext } from 'src/context/modal';
import PlanoDeAssinaturaForm from './Modal';
import useApi from 'src/hooks/useApi';
import FormModalDelete from 'src/components/Basicos/Form/FormModalDelete';
import { IFuncionalidadePlano, IPeriodicidadePlanoAssinatura, IPlanoComFuncionalidadesEValores, IPlanoDeAssinatura } from 'src/@types/entities';

function PlanosDeAssinaturas(): JSX.Element {

    const endpoint = '/crud/PlanoAssinatura?per_page=1000';
    const modal = useModalContext();
    const api = useApi({ endpoint });

    const [action, setAction] = useState<'CREATE' | 'UPDATE' | 'DELETE' | undefined>(undefined);

    const [periodicidadesDisponiveis, setPeriocididadesDisponiveis] = useState<IPeriodicidadePlanoAssinatura[] | any[]>([]);
    const [funcionalidadesDisponiveis, setFuncionalidadesDisponiveis] = useState<IFuncionalidadePlano[] | any[]>();

    const [plano, setPlano] = useState<IPlanoComFuncionalidadesEValores | undefined>();

    useEffect(() => {
        (async function () {
            const response = await axiosApi.get("/crud/PeriodicidadePlanoAssinatura?per_page=1000");
            console.log('response', response.data?.data)
            setPeriocididadesDisponiveis(response.data?.data);
        })();

        (async function () {
            const response = await axiosApi.get("adm-funcionalidade-plano");
            setFuncionalidadesDisponiveis(response.data.dados?.funcionalidades);
        })();

    }, []);

    useEffect(() => {
        if (action === 'UPDATE') {
            console.log(plano);
            modal.handle(
                <PlanoDeAssinaturaForm
                    periodicidadesDisponiveis={periodicidadesDisponiveis}
                    funcionalidadesDisponiveis={funcionalidadesDisponiveis}
                    action={action}
                    item={plano}
                />
            );
        }
    }, [plano]);

    //Items do cabeçalho da tabela.
    const reactTableColumns: ReactTableColumn[] = [
        { Header: "Código", accessor: "id" },
        { Header: "Descrição", accessor: "ds_nome_plano_assinatura" },
        {
            Header: "Status",
            Cell: ({ row }: UseTableCellProps<IPlanoDeAssinatura>) => (
                <div className="">
                    {row.original.st_ativo === true ? 'Ativo' : 'Inativo'}
                </div>
            ),
            accessor: "st_ativo"
        },
        {
            Header: '',
            accessor: 'acoes',
            Cell: ({ row }: UseTableCellProps<IPlanoDeAssinatura>) => (
                <TableActionsMenu
                    onClickToDelete={(e: any) => _deletePlano(e, row.original.id)}
                    onClickToEdit={(e: any) => _updatePlano(e, row.original.id)}
                />
            )
        }
    ];

    // Atualiza os dados da tabela conforme hajam mudanças em tableData.
    const { tableData, tableColumns, getItem } = useReloadTable<IPlanoDeAssinatura>(reactTableColumns, async setTableData => {
        const response = await axiosApi.get(endpoint);
        setTableData(response.data.data);
    });

    function _createPlano(event: React.MouseEvent<HTMLSpanElement, any>) {

        setAction('CREATE');
        setPlano(undefined);

        modal.handle(
            <PlanoDeAssinaturaForm
                periodicidadesDisponiveis={periodicidadesDisponiveis}
                funcionalidadesDisponiveis={funcionalidadesDisponiveis}
                action={action}
            />
        );
    }

    function setPlanoById(id: number | undefined) {
        (async () => {
            const response = await axiosApi.get(`crud/PlanoAssinatura/${id}`);
            // const response = await axiosApi.get(`planos/${id}/funcionalidades-e-valores`);
            console.log(response.data)
            setPlano(response.data);
        })();
    }

    function _updatePlano(event: any, id: number | undefined) {
        setAction('UPDATE');
        setPlanoById(id);
    }

    const _deletePlano = (event: any, id: number | undefined) => {

        setAction('DELETE');

        modal.handle(
            <FormModalDelete
                title={"Plano de Assinatura"}
                onSubmit={(_) => api.destroy(event, id)}
            />
        );
    }

    return (
        <Fragment>
            <CustomTableWithTitle
                tableTitle="Planos de Assinaturas"
                tableData={tableData}
                tableColumns={tableColumns}
                onClickToCreate={(e: React.MouseEvent<HTMLSpanElement, any>) => _createPlano(e)}
            />
        </Fragment>
    );
}

export default PlanosDeAssinaturas;