import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

export default function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
  
    return (
      <span style={{ marginLeft: '6.5%' }}>
        <input
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={''}
          className="input-search-filter w-full"
          id="estilo-placeholder-table-usuario"
          style={{
            backgroundColor: '#FFF',
            borderRadius: '24px',
            height: '36px',
            border: '1.2px solid rgba(41, 41, 44, 0.12)',
            outline: 'none',
            padding: '20px'
          }}
        />
      </span>
    )
  }