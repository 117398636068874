import React, { useEffect, useState } from 'react';
import SelectForm from 'src/components/Basicos/Form/SelectForm/index.jsx';
import { success, transformDataToOptionsSelectForm } from 'src/components/Basicos/funcoes';
import { axiosApi } from 'src/services/axiosInstance';
import './style.css';

interface Dados {
    id_categoria?: number;
    id?: number;
    nome_sub?: string;
}
type Props = {
    dados?: Dados;
    closeModal: any;
    dataArray: any;
}
type Categoria = {
    id_categoria: number | undefined;
    nome: string | undefined;
    value: any | undefined;
}
const SubCategoriaForm: React.FC<Props> = ({ dados, closeModal, dataArray }: Props) => {
    const [data, setData] = useState<Dados>({});
    const [selectOptions, setSelectOptions] = useState<Array<Categoria>>([]);

    const loadCategoria = async (): Promise<void> => {
        const response = await axiosApi.get('adm-categoria');
        const { dados } = response.data;
        setSelectOptions(transformDataToOptionsSelectForm(dados));
    }

    useEffect(() => {
        loadCategoria();
    }, []);

    const onChangeEventInput = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
        const { name, value, } = event.target;
        setData((prevState) => ({ ...prevState, [name]: value }));
    }

    const onChangeEventSelect = (name: string, value: number): void => {
        setData((prevState) => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        if (dados)
            setData(dados);
    }, [dados]);

    const submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            const endpoint = '/crud/SubCategoria';
            const method = dados ? "PUT" : "POST";
            const url = dados ? `${endpoint}/${data?.id}` : endpoint;
            const response = await axiosApi(
                {
                    method,
                    url,
                    data
                }
            );

            if (response.status === 201 && method === "POST") {
                const { dados } = response.data;
                success('Adicionado com sucesso!');
                dataArray.push(dados)
            } else {
                success('Atualizado com sucesso!');
            }
            closeModal();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <form className="flex flex-col" onSubmit={submitForm} >
            <label className="form-label-titulo mt-4">Categoria</label>
            <SelectForm
                name={'id_categoria'}
                options={selectOptions}
                value={selectOptions.find(element => element.value === data?.id_categoria)}
                onChange={onChangeEventSelect}
                placeholder={"Selecione uma Categoria"}
                className={"modal-input mt-3 p-1"}
            />
            <label className="form-label-titulo my-3">Descrição da Subcategoria</label>
            <input
                required
                type="text"
                name="nome_sub"
                onChange={onChangeEventInput}
                value={data.nome_sub ?? ""}
                className="modal-input mb-3 p-1"
            />
            <button className="btn">Salvar</button>
        </form>
    );
}
export default SubCategoriaForm;