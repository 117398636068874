import React, { Ref, useEffect, useState } from "react";
import { ISelectOption, IThreeColsOneRow } from "src/@types/components";
import { TrashAndPlusIconButtons, TrashIconButton } from "../IconButtons";
import ThreeColsOneRow from "../ThreeColsOneRow";

interface IListThreeColsOneRow<Entity> extends Omit<IThreeColsOneRow, 'children' | 'names' | 'labels' | 'options'> {
    children?: React.ReactNode;
    handleRef?: Ref<any>;
    items?: Entity[] | undefined;
    parentItemName: string;
    names: string[];
    labels: (string | undefined)[];
    options: ISelectOption[];
    getListItems: (items: Entity[] | undefined) => void;
}


export default function ListThreeColsOneRow<Entity>(props: IListThreeColsOneRow<Entity>): JSX.Element {

    const [listItems, setListItems] = useState<Entity[] | undefined>(props?.items ?? []);

    const [changeableOne, setChangeableOne] = useState<number | undefined>();
    const [changeableTwo, setChangeableTwo] = useState<number | undefined>();

    useEffect(() => {
        clearChangeables();
    }, []);


    useEffect(() => {
        props?.getListItems(listItems)
    }, [listItems]);

    function clearChangeables() {
        setChangeableOne(1);
        setChangeableTwo(0);
    }

    const getSelectOptionById = (id: number): ISelectOption => (props?.options ?? []).filter(el => el.value === id)[0];

    const handleChangeableOne = (event: React.ChangeEvent<any>) => setChangeableOne(parseFloat(event.target.value));

    const handleChangeableTwo = (event: React.ChangeEvent<any>) => {
        const value = (changeableTwo === 0 && event.target.value[0] === '0') ? event.target.value.substring(1) : event.target.value;
        event.target.value = value;
        return setChangeableTwo(parseFloat(value));
    };

    function removeValueFromList(index: number) {
        if (!!listItems) {

            setListItems(prevState => {

                const arr = [...listItems];
                arr.splice(index, 1);
                return arr;

            });
        }
    }

    function addItemToList(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        if ((!!changeableOne && !!changeableTwo)) {
            const _entity: any = {
                [props.names[0]]: changeableOne,
                [props.names[1]]: changeableTwo
            };

            setListItems((prevState) => ((_prevState: Entity[]) => ([..._prevState, _entity]))((prevState ?? [])));
            clearChangeables();
        }
    }

    return (
        <div className="p-3">
            <div className="mb-5">
                <div className="flex flex-col justify-center">
                    {!!listItems && listItems.map((item, i) => (
                        <ThreeColsOneRow
                            key={i}
                            readOnly
                            labels={props?.labels}
                            names={[`${props?.parentItemName}.${props?.names[0]}`, `${props?.parentItemName}.${props?.names[1]}`]}
                            values={[getSelectOptionById(item[props.names[0]] ?? 1)?.label, item[props.names[1]]]}
                            handleRef={props?.handleRef}
                        >,
                            <TrashIconButton onClick={() => removeValueFromList(i)} />
                        </ThreeColsOneRow>
                    ))}
                </div>
            </div>

            <ThreeColsOneRow
                onChangeHandlers={[handleChangeableOne, handleChangeableTwo]}
                values={[changeableOne, changeableTwo]}
                labels={props?.labels}
                options={props?.options}
                handleRef={props?.handleRef}
            >
                <TrashAndPlusIconButtons onTrash={clearChangeables} onPlus={addItemToList} />
            </ThreeColsOneRow>
        </div>
    );
}