import React, { Fragment } from 'react'
import TableTitle from '../TableTitle';
import { CustomTableWithTitleProps } from 'src/@types';
import CustomTable from '../CustomTable';
// import ModalDelete from 'src/components/Funcionalidades/ModalDelete';

/**
 * Tabela genérica com filtragem, paginação,
 * título e botões para CRUD.
 */
function CustomTableWithTitle(props: CustomTableWithTitleProps) {

    return (
        <Fragment>
            <div className="p-5">
                <CustomTable
                    /**
                     * Tabela customizada do sistema.
                     */
                    columns={props?.tableColumns}
                    data={props?.tableData}
                    /**
                     * TableTitleComponent será o componente
                     * que aparecerá logo acima da tabela.
                     */
                    TableTitleComponent={
                        () => <TableTitle
                            onClickToCreate={props.onClickToCreate}
                            title={props.tableTitle}
                        />
                    }
                />
            </div>
        </Fragment>
    );
}

export default CustomTableWithTitle;
