import Axios from 'axios';
import { error as errorToast } from '../components/Basicos/funcoes';

const token = localStorage.getItem("token-iserv");

if (
  window.location.protocol !== "https:" &&
  process.env.NODE_ENV === "production" &&
  window.location.hostname !== "localhost" &&
  window.location.hostname !== "127.0.0.1"
) {
  window.location.replace(
    `https:${window.location.href.substring(window.location.protocol.length)}`
  );
}

export const axiosApi = Axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
  timeout: 240000,
  headers: {
    'Authorization': token ? `bearer ${token}` : "",
    "Content-Type": "application/json",
  },
});

// export const errorInterceptor = axiosApi.interceptors.response.use(
//   function (response) {
//     return response;
//   }, async function (error) {

//     const isPropositalError: boolean = !!error.response.data.erro;

//     if (error.response.status === 401) {
//       if (isPropositalError) {
//         return errorToast(error.response.data.erro);
//       }
//       localStorage.clear();
//       localStorage.setItem('hasTheSessionExpired', 'true');
//       window.location.reload();
//     }
//     return Promise.reject(error);
//   });



export const refreshToken = () => {
  const expiresIn: number | any = String(localStorage.getItem("iserv-expires"));
  setTimeout(async () => {
    var response = await axiosApi.post("/refresh");
    const { access_token, expires_in } = response.data;
    localStorage.setItem('token-iserv', access_token);
    localStorage.setItem('iserv-expires', expires_in.toString());
    axiosApi.defaults.headers.common['authorization'] = `bearer ${access_token}`;
  }, expiresIn - 300, () => refreshToken());
};

export default axiosApi
