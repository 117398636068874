import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { axiosApi } from '../../../services/axiosInstance';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt-BR';
import NewLoader from 'src/components/Basicos/NewLoader';
import { formatDate, parseDate } from '../funcoes';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.css';
// registerLocale('pt', pt)/

const GraphCard = ({ }) => {
  const [dataGraphCard, setDataGraphCard] = useState({});
  const [selectOption, setSelectOption] = useState('dia');
  const [selectOptionPeriodo, setSelectOptionPeriodo] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());


  const loadDataGraphCard = async () => {
    try {
      const response = await axiosApi.get(`total-visitas?tipo=${selectOption ? selectOption : 'dia'}`);
      setDataGraphCard(response.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  const handleOptiononClick = (clickEvent) => {
    setSelectOption(clickEvent);
    setSelectOptionPeriodo(clickEvent); 
    setStartDate(new Date());
    
  }

  const formatLaravelDateMes = 'yyyy-MM';


  useEffect(() => {
    loadDataGraphCard();
  }, [selectOption]);


  return (

    <div className="grid grid-cols-6 md:grid-cols-6 rounded-lg shadow shadow-md p-3 gap-2">
      <div className="col-start-1 col-span-2">
        <span className="main-home-titulo">Total de Visitas</span>
      </div>
      <div className="col-start-11 col-end-12 col-span-2">
        <button className={`sm:col-span-6 md:col-span-6 lg:col-span-1  w-20 shadow py-1 mx-4 rounded-full px-1 ${(selectOption === 'dia') ? 'main-mes' : ''}`} onClick={() => { handleOptiononClick('dia') }}  disabled={(selectOption === 'dia') ? true : false} >Dia</button>
        <button className={`sm:col-span-6 md:col-span-6 lg:col-span-1  w-20 shadow py-1 mx-4 rounded-full px-1 ${(selectOption === 'semana') ? 'main-mes' : ''}`} onClick={() => { handleOptiononClick('semana') }} disabled={(selectOption === 'semana') ? true : false} >Semana</button>
        <button className={`sm:col-span-6 md:col-span-6 lg:col-span-1  w-20 shadow py-1 mx-4 rounded-full px-1 ${(selectOptionPeriodo === 'mes') ? 'main-mes' : ''}`} onClick={() => { handleOptiononClick('mes') }} disabled={(selectOptionPeriodo === 'mes') ? true : false} >Mês</button>
        <button className={`sm:col-span-6 md:col-span-6 lg:col-span-1  w-40 shadow py-1 mx-4 rounded-full px-1 ${(selectOptionPeriodo === '12meses') ? 'main-mes' : ''}`} onClick={() => { handleOptiononClick('12meses') }} disabled={(selectOptionPeriodo === '12meses') ? true : false} >Mês Anterior</button>
      </div>
      <div className={`col-end-12 col-span-2 sm:col-span-6 md:col-span-6 lg:col-span-1  w-40 rounded-full mx-4 px-2  p-1`} style={{ display: 'flex' }}>
        <DatePicker
          selected={startDate}
          parseDate={parseDate}
          onSelect={(date) => setStartDate(date)}
          onChange={(date) => setSelectOption(`${selectOptionPeriodo}&periodo=${formatDate(date, formatLaravelDateMes)}`)}
          className="date-picker-home shadow"
          dateFormat="MM/yyyy"
          locale={pt}
          showMonthYearPicker
        />
        <FontAwesomeIcon icon={faCalendarAlt} style={{ zIndex: '50', marginTop: '5px' }} />
      </div>
      <div class="col-start-1 col-end-13">
        <NewLoader isActive={isLoading} overlay>
          <ResponsiveContainer
            debounced={300}
            height={290}
            width="99%">
            <AreaChart
              height={250}
              data={dataGraphCard}
              margin={{
                top: 20
              }}
              width="100%"
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#00FFCC" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#00FFCC" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid />
              <XAxis dataKey="datakey" unit={`${(selectOption === 'dia') ? 'h' : ''}`}/>
              <YAxis />
              <Tooltip />
              <Area type="monotone"
                dataKey="visita"
                stroke="#00FFCC"
                fill="url(#colorUv)"
                fill-opacity={1}
                strokeWidth={5}
                className="stroke"
              />
            </AreaChart>
          </ResponsiveContainer>
        </NewLoader>
      </div>
    </div>
  );
}
export default GraphCard;
