import React, { Fragment, PureComponent } from 'react';
import { FaCrown } from 'react-icons/fa';
import { BarChart, Bar, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import './style.css';

const data = [
    {
        name: '1', uv: 150, pv: 2400, amt: 2400
    },
];

export default class BarGraphCardPlus extends PureComponent {
    render() {
        return (
            <div id="bar-graph-azul">
                
                <BarChart width={100} height={100} data={data} >
                    <XAxis dataKey="name" stroke="#223066" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="uv" fill="#223066" barSize={30} width="500"/>
                </BarChart>
                <button className="btn-bar-azul">
                <FaCrown stroke="#fff" className="mr-2"/>PLUS</button>
            </div>
        );
    }
}
