import React from 'react';
import { Accordion } from "react-accessible-accordion";
import ConsultasCategorias from './ConsultasCategorias';

const Consultas : React.FC = () => {

    return (
        <React.Fragment>
            <Accordion allowMultipleExpanded={true}>
                <ConsultasCategorias/>
            </Accordion>
        </React.Fragment>
    );
}

export default Consultas;