import React, { useEffect, useState } from 'react';
import { success } from 'src/components/Basicos/funcoes';
import { axiosApi } from 'src/services/axiosInstance';
import './style.css';

interface Dados {
    id_duvida?: number,
    id_cliente_usuario?: number,
    descricao_duvida?: string,
    resposta_duvida?: string,
    tb_cliente_usuario?: {
        id_cliente_usuario?: number,
        nome_usuario?: string
    }
}
type Props = {
    dados?: Dados;
    closeModal: any;
    dataArray: any;
}

const DuvidaForm: React.FC<Props> = ({ dados, closeModal,dataArray }: Props) => {

    const [data, setData] = useState<Dados>({});

    const onChangeEventInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value, } = event.target;
        setData({ ...data, [name]: value });
    }

    useEffect(() => {
        if (dados){
            setData({...dados
            });   
        }
    }, [dados]);

    const submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            const endpoint = 'adm-duvida';
            const method = dados ? "PUT" : "POST";
            const url = dados ? `${endpoint}/${data?.id_duvida}` : endpoint;
            const response = await axiosApi(
                {
                    method,
                    url,
                    data
                }
            );

            if(response.status === 201 && method === "POST"){
                const { dados } = response.data;
                success('Adicionado com sucesso!');
                dataArray.push(dados)
            }else {
                success('Atualizado com sucesso!');
            }
            closeModal();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <form className="flex flex-col my-5" onSubmit={submitForm} >
            <label className="form-titulo my-3 text-left">Pergunta</label>
            <input
                type="text"
                name="nome"
                onChange={onChangeEventInput}
                value={ data?.descricao_duvida ?? ""}
                className="modal-input mb-3 pl-2"
            />
             <label className="form-titulo my-3 text-left">Resposta</label>
            <input
                type="text"
                name="nome"
                onChange={onChangeEventInput}
                value={ data?.resposta_duvida ?? ""}
                className="modal-input mb-3 pl-2"
            />
            <button className="btn">{dados ? 'Atualizar' : 'Salvar'}</button>
        </form>
    );
}
export default DuvidaForm;