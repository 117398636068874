// import { env } from 'process';
import React from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";
import { IRoute } from './@types';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Main from './pages/Main';
import { refreshToken } from './services/axiosInstance';

import './tailwind.output.css';

const RotasNaoAutenticada: React.FunctionComponent<IRoute> = (props) => {
    return (
        <Switch>
            <Route path={'/login'} >
                <Login />
            </Route>
            <Route>
                <Redirect to="/login" />
            </Route>
        </Switch>
    )
}

const RotasAutenticada: React.FunctionComponent<IRoute> = (props) => {

    refreshToken();

    return (
        <Switch>
            <Route path={'/'} >
                <Main />
            </Route>
            <Route path="/auth/logout" >
                <Logout />
            </Route>
            <Route >
                <Redirect to="/" />
            </Route>
        </Switch>
    )
}

const App: React.FunctionComponent = () => {
    const token = localStorage.getItem("token-iserv");
    return (
        <Router>
            {token ? <RotasAutenticada token={token} /> : <RotasNaoAutenticada token={token ?? ""} />}
        </Router>
    );
}

export default App;
