import React, { Fragment } from 'react';
import Sidebar from 'src/components/Funcionalidades/Sidebar';
import Conteudo from 'src/components/Funcionalidades/Conteudo';
import WhiteHeader from 'src/components/Basicos/WhiteHeader';

function Main() {
    return (
        <Fragment>
            <WhiteHeader />
            <Sidebar />
            <div>
                <Conteudo />
            </div>
        </Fragment>
    )
}

export default Main;