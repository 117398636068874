import React, { Fragment } from 'react'
import { ModalGroupProps } from 'src/@types';
import ModalCreateOrUpdate from 'src/components/Funcionalidades/ModalCreateOrUpdate';
import ModalDelete from 'src/components/Funcionalidades/ModalDelete';

function ModalGroup ({
    title,
    isMale = false,
    rowId,
    endpoint,
    openCreateOrUpdateModal,
    setOpenCreateOrUpdateModal,
    openDeleteModal,
    setOpenDeleteModal,
    reloadTable,
    children: ModalContent,
}: ModalGroupProps) {
    
    function handleCloseDeleteModal() {
        setOpenDeleteModal(false);
        reloadTable();
    }

    return (
        <Fragment>
            <ModalCreateOrUpdate
                open={openCreateOrUpdateModal}
                setOpen={setOpenCreateOrUpdateModal}
                title={title}
                rowId={rowId}
                isMale={isMale}
            >
                {ModalContent}
            </ModalCreateOrUpdate>
            <ModalDelete
                titulo={title}
                open={openDeleteModal}
                rota={endpoint}
                id={rowId}
                closeModal={handleCloseDeleteModal}
            />
        </Fragment>
    );
}

export default ModalGroup;
