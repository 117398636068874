import React, { useEffect, useState } from 'react';
import ListThreeColsOneRow from 'src/components/Basicos/ListThreeColsOneRow';
import { useSelectOptions } from 'src/hooks/useSelectOptions';
import { IFuncionalidadePlano, IFuncionalidadeSituacao } from 'src/@types/entities/index';
import { IFormTabs, ISelectOption } from 'src/@types/components';

interface ITabFuncionalidades extends IFormTabs<IFuncionalidadeSituacao[] | undefined> {
    options: IFuncionalidadePlano[] | undefined;
    funcionalidades: IFuncionalidadeSituacao[] | undefined;
    setFuncionalidades: React.Dispatch<React.SetStateAction<IFuncionalidadeSituacao[] | undefined>>;
}

export default function TabFuncionalidades(props: ITabFuncionalidades): JSX.Element {

    const { options } = useSelectOptions<IFuncionalidadePlano>(
        props.options ?? [{ id_funcionalidade_plano_assinatura: 1, nome_funcionalidade: '' }],
        element => ({
            value: element.id_funcionalidade_plano_assinatura,
            label: `${element.id_funcionalidade_plano_assinatura.
                toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} - ${element.nome_funcionalidade}`,
        })
    );

    return (
        <ListThreeColsOneRow 
        // <ListThreeColsOneRow <IFuncionalidadeSituacao>
            parentItemName="tb_funcionalidade_situacao"
            names={['id_funcionalidade_plano_assinatura', 'nr_quantidade_funcionalidade']}
            labels={['Funcionalidade', 'Quantidade']}
            options={options}
            items={props?.funcionalidades}
            getListItems={items => props.setFuncionalidades(items)}
        />
    );
}