import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import Table from 'src/components/Basicos/Table';
import { UseTableCellProps } from 'react-table';
import ModalAdicionarEditar from 'src/components/Funcionalidades/Modal';
import { axiosApi } from 'src/services/axiosInstance';
import CategoriaForm from '../FormsAuxiliar/_Categoria';
import SubCategoria from '../SubCategoria';
import ModalDelete from 'src/components/Funcionalidades/ModalDelete';
import ColoredContentButton from 'src/components/Basicos/ColoredContentButton';
import { PlusBlueIcon } from 'src/assets/icons';


type CategoriaProps = {
    id: number;
    nome?: string;
    ds_nome_imagem?: string;
    qtd_servico?: number;
    url?: string;
}

const Categoria: React.FC = () => {
    const [data, setData] = useState<Array<CategoriaProps>>([]);
    const [open, setOpen] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [id, setId] = useState<any>();
    const [index, setIndex] = useState<any>();

    const closeModal = (): void => {
        setOpen(false);
        setId(undefined);
    }

    const closeModalDelete = () => setOpenModalDelete(false);

    const openModalDeleteFunc = (id: number): void => {
        setOpenModalDelete(true);
        setId(id);
    }

    const openModal = (): void => {
        setOpen(true);
    }

    const loadCategoria = async (): Promise<void> => {
        const response = await axiosApi.get('/adm-categoria');
        setData(response.data.dados);
    }

    const getCategoriaPeloIndice = (index: number): CategoriaProps | undefined => {
        return data[index] ?? undefined;
    }

    useEffect(() => {
        loadCategoria();
    }, [id]);

    const columns = [
        {
            Header: "Código",
            accessor: "id"
        },
        {
            Header: "Nome",
            accessor: "nome"
        },
        {
            Header: "Serviços",
            accessor: "servicos"
        },
        {
            Header: "Subcategorias",
            accessor: "qtd_sub_categoria"
        },
        {
            Header: "",
            resizable: true,
            accessor: "acoes",
            Cell: ({ row }: UseTableCellProps<CategoriaProps>) => {

                return (
                    <Fragment>
                        <div className="flex flex-row p-0 justify-center">
                            <ColoredContentButton
                                onClick={(e) => {
                                    getCategoriaPeloIndice(row.index);
                                    setId(row.index);
                                    setOpen(true);
                                }}
                            >
                                <FiEdit />
                            </ColoredContentButton>
                            <ColoredContentButton
                                name="delete"
                                onClick={() => {
                                    setIndex(row.index);
                                    openModalDeleteFunc(row.original.id)
                                }}
                            >
                                <FiTrash2 />
                            </ColoredContentButton>
                        </div>
                    </Fragment>
                );
            }
        }
    ]
    return (
        <Fragment>
            <div className="mt-24 ml-8 home-box sombra p-3 grid sm:grid-cols-1 md:grid-cols-6 lg:grid-cols-12">
                <div className="flex">
                    <span className="font-bold mt-1 mr-3 lg:col-start-1 lg:col-span-1 mb-6 mr-2 ml-4">Categorias</span>
                    <span
                        onClick={openModal}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        <span className="cursor-pointer">
                            <PlusBlueIcon />
                        </span>
                    </span>
                </div>
                <div className="grid lg:col-span-12 lg:col-start-1">
                    <Table columns={columns} data={data} />
                </div>
                <ModalAdicionarEditar
                    open={open}
                    closeModal={closeModal}
                    titulo="Categoria"
                    dados={id ?? undefined}
                >
                    <CategoriaForm
                        dados={id !== undefined ? getCategoriaPeloIndice(id) : undefined}
                        closeModal={closeModal}
                        dataArray={data}
                    />
                </ModalAdicionarEditar>
                <ModalDelete
                    open={openModalDelete}
                    closeModal={closeModalDelete}
                    rota={'/crud/Categoria'}
                    titulo={'Categoria'}
                    id={id}
                    index={index !== undefined ? getCategoriaPeloIndice(index) : undefined}
                    data={data}
                />
            </div>
            <SubCategoria />
        </Fragment>
    );
}

export default Categoria;