import React, { Fragment, useEffect, useState } from 'react';
import Anuncios from 'src/components/Basicos/Cards/CardAnuncio';
import ContratoDePlanos from 'src/components/Basicos/Cards/CardContratoPlanos';
import CardServico from 'src/components/Basicos/Cards/CardServico';
import TipoUsuarios from 'src/components/Basicos/Cards/CardTipoUsuario';
import CardUsuario from 'src/components/Basicos/Cards/CardUsuario';
import GraphCard from 'src/components/Basicos/Graph';
import GraphPie from 'src/components/Basicos/GraphPie';
import BarGraphCard from '../../components/Basicos/GraphBar';
import NewLoader from 'src/components/Basicos/NewLoader';
import { axiosApi } from '../../services/axiosInstance';

import './style.css';

function Home() {

    const [dataPieGraph, setDataPieGraph] = useState({});
    const [dataBarGraph, setDataBarGraph] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    const loadDataPieGraph = async () => {
      try {
        const response = await axiosApi.get(`/total-usuarios`)
        setDataPieGraph(response.data)
        setIsLoading(false)
      } catch (e) {
        console.log(e);
      }
    }

    const loadDataBarGraph = async () => {
      try {
        const response = await axiosApi.get(`/total-planos`)
        setDataBarGraph(response.data)
      } catch (e) {
        console.log(e);
      }
    }
    
    useEffect(() => {
      loadDataPieGraph();
      loadDataBarGraph();
    }, []);

    return (
        <Fragment>
            <NewLoader isActive={isLoading} overlay>
                <div className="flex mb-5 home-box ml-6" id="grafico-topo-principal">
                    <GraphCard />
                    <div className="flex flex-col">
                        <CardUsuario />
                        <CardServico />
                    </div>
                </div>
                <div className="flex mb-5 home-box ml-6" style={{height:'330px'}}>
                    <GraphPie dadosPie={dataPieGraph} />
                    <BarGraphCard dadosBar={dataBarGraph} />
                    <Anuncios />
                </div>
            </NewLoader>

        </Fragment>
    )
}
export default Home;