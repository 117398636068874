import React, { Fragment } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import ColoredContentButton from '../ColoredContentButton';
import { TableActionsMenuProps } from 'src/@types';

function TableActionsMenu({
    onClickToEdit,
    onClickToDelete,
    justUpdateButton = false,
    justDeleteButton = false,
}: TableActionsMenuProps) {

    function DeleteButton() {
        return <ColoredContentButton className="btn-table" name="delete" onClick={onClickToDelete}>
            <FiTrash2 />
        </ColoredContentButton>;

    }

    function EditButton() {
        return <ColoredContentButton className="btn-table" onClick={onClickToEdit}>
            <FiEdit />
        </ColoredContentButton>;
    }

    function CheckButtonOptions() {
        if (justDeleteButton)
            return <DeleteButton />

        if (justUpdateButton)
            return <EditButton />

        return <Fragment>
            <EditButton />
            <DeleteButton />
        </Fragment>
    }

    return (
        <div className="flex flex-row p-0">
            <CheckButtonOptions />
        </div>
    )
};

export default TableActionsMenu;
