import { useEffect, useState } from "react";
import { ISelectOption } from "src/@types/components";

export function useSelectOptions<T>(arr: T[], callback: (element: T) => ISelectOption) {

    const [options, setOptions] = useState<ISelectOption[]>([]);

    useEffect(() => {

        const _options = arr.map((el: T) => {
            return callback(el);
        });
        setOptions(_options);

    }, [arr]);

    return { options, setOptions };

}