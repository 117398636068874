import React from 'react';
import { useModalContext } from 'src/context/modal';

type Props = {
    title: string;
    onSubmit?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const FormModalDelete: React.FC<Props> = ({ title, onSubmit }: Props): JSX.Element => {

    const modal = useModalContext();

    return (
        <div className="modal">
            <div className="topo-modal-adicionar-editar">
                <div className="icone-voltar-modal" onClick={modal.close}>
                    <img src={require('../../../../assets/icones/seta-esquerda.png')} />
                </div>
                <div className="titulo-modal-adicionar-voltar">
                    <h1>{title}</h1>
                </div>
            </div>
            <div>
                <p className="my-4">Deseja deletar esse item?</p>
                <button
                    className="btn"
                    onClick={onSubmit}>Deletar</button>
            </div>
        </div>
    );
}

export default FormModalDelete;