import React from 'react';
import { ColoredContentButtonProps } from 'src/@types';

export default function ColoredContentButton({ children, color, onClick, ...rest }: ColoredContentButtonProps): React.ReactElement {
    return (
        <button {...rest}
            className={`
                border border-gray-300 p-2 rounded mr-3 focus:outline-none 
                ${color ?? 'text-blue-800'}`
            }
            onClick={onClick}>
            {children}
        </button>
    );
};