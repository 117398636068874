import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ToggleSwitch } from 'src/components/Basicos/ToggleSwitch';
import './style.css';
import { useModalContext } from 'src/context/modal';
import TabValores from './Tabs/TabValores';
import TabGeral from './Tabs/TabGeral';
import TabFuncionalidades from './Tabs/TabFuncionalidades';
import { IFuncionalidadePlano, IFuncionalidadeSituacao, IPeriodicidadePlanoAssinatura, IPlanoComFuncionalidadesEValores, IPlanoDeAssinatura, IValorPlanoAssinatura } from 'src/@types/entities';
import useApi from 'src/hooks/useApi';
// import { useFormContext } from 'src/context/form';

interface IPlanoDeAssinaturaForm {
    action: 'CREATE' | 'UPDATE' | 'DELETE' | undefined;
    item?: IPlanoComFuncionalidadesEValores | undefined;
    funcionalidadesDisponiveis?: IFuncionalidadePlano[] | any[];
    periodicidadesDisponiveis?: IPeriodicidadePlanoAssinatura[] | any[];
};

export default function PlanoDeAssinaturaForm(props: IPlanoDeAssinaturaForm): JSX.Element {


    const modal = useModalContext();
    const api = useApi({ endpoint: 'planos/funcionalidades-e-valores', isMultipart: false });

    const [nomePlano, setNomePlano] = useState<string | undefined>(props?.item?.ds_nome_plano_assinatura);
    const [statusDoPlano, setStatusDoPlano] = useState<boolean>(!!props?.item?.st_ativo);
    const [funcionalidades, setFuncionalidades] = useState<IFuncionalidadeSituacao[] | undefined>(props?.item?.tb_funcionalidade_situacao);
    const [valores, setValores] = useState<IValorPlanoAssinatura[] | undefined>(props?.item?.tb_valor_plano_assinatura);

    // const form = useFormContext();

    // useEffect(() => {
    //     console.log(props?.item);
    // }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        const data = {
            ds_nome_plano_assinatura: nomePlano,
            st_ativo: statusDoPlano,
            tb_funcionalidade_situacao: (funcionalidades ?? []).map(funcionalidade => ({ ...funcionalidade, st_ativo: true })) ?? [],
            tb_valor_plano_assinatura: valores ?? []
        }

        if (props?.action === 'UPDATE') {

            const id = props?.item?.id;
            api.update(event, data, false,`/crud/PlanoAssinatura/${id}`);
            return;
        }

        api.create(event, data);
    }

    // useEffect(() => {
    //     console.log('PARENT: ', nomePlano, funcionalidades, valores, statusDoPlano);
    // }, [nomePlano, funcionalidades, valores, statusDoPlano]);

    return (
        <div className="modal px-0 pt-5">
            <div className="flex flex-row items-center justify-between">
                <div className="pl-5 h-full w-full cursor-pointer" onClick={modal.close}>
                    <img className="w-3" src={require('../../../../assets/icones/seta-esquerda.png')} />
                </div>
                <div className="text-2xl text-center font-bold mb-5 w-full h-full">
                    Novo Plano
                </div>
                <div className="w-full h-full">
                    <ToggleSwitch
                        defaultValue={!props?.item?.st_ativo}
                        toggleStates={{ on: 'Ativo', off: 'Inativo' }}
                        callback={status => setStatusDoPlano(status)}
                    />
                </div>
            </div>
            <form className="flex flex-col" onSubmit={handleSubmit} >
                <Tabs>
                    <TabList>
                        <Tab className="cursor-pointer">Geral</Tab>
                        <Tab className="cursor-pointer">Funcionalidades</Tab>
                        <Tab className="cursor-pointer">Valores</Tab>
                    </TabList>
                    <div className="p-5">
                        <TabPanel>
                            <TabGeral
                                nomePlano={nomePlano}
                                setNomePlano={setNomePlano}
                            />
                        </TabPanel>
                        <TabPanel>
                            <TabFuncionalidades
                                options={props?.funcionalidadesDisponiveis}
                                funcionalidades={funcionalidades}
                                setFuncionalidades={setFuncionalidades}
                            />
                        </TabPanel>
                        <TabPanel>
                            <TabValores
                                options={props?.periodicidadesDisponiveis}
                                valores={valores}
                                setValores={setValores}
                            />
                        </TabPanel>
                    </div>
                    <button className="btn" disabled={false} >
                        Salvar
                    </button>
                </Tabs>
            </form>
        </div>
    );
}