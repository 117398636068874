import React, { useEffect, useState } from 'react'
import ReactSwitch from 'react-switch';
import { ToggleSwitchProps } from 'src/@types';

export function ToggleSwitch({ defaultValue, toggleStates, callback }: ToggleSwitchProps) {

    const [isActive, setIsActive] = useState<boolean>(!defaultValue);
    const [toggleMessage, setToggleMessage] = useState<string>(toggleStates.off);

    /**
     * Caso hajam mudanças no estado do toggle,
     * a mensagem mudará junto com ele.
     */
    useEffect(() => {
        setToggleMessage(
            (isActive) ? toggleStates.on : toggleStates.off
        )
        callback(isActive);
    }, [isActive]);

    /**
     * Gerencia se o switch está ligado ou desligado.
     */
    function handleToggleChanges() {
        setIsActive(!isActive);
    }

    return (
        <div>
            <label className="form-label-titulo align-top">{toggleMessage}</label>
            <ReactSwitch
                name="st_ativo"
                onChange={handleToggleChanges}
                className="toggle"
                checked={isActive}
                offColor={'#919191'}
                onColor={'#223066'}
                uncheckedIcon={false}
                checkedIcon={false}
            />
        </div>
    );
}
