import React from 'react';
import { FiPlus, FiTrash } from "react-icons/fi";

interface ITrashIconButton {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function TrashIconButton({ onClick }: ITrashIconButton) {
    return (
        <div className="flex flex-row items-end h-full ml-3">
            <button type="button" onClick={onClick} className="p-2 flex flex-col justify-center rounded focus:outline-none">
                <FiTrash className="text-blue-700" />
            </button>
        </div>
    );
}

interface ITrashAndPlusIconButtons {
    onTrash?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onPlus?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function TrashAndPlusIconButtons({ onTrash, onPlus }: ITrashAndPlusIconButtons) {
    return (
        <div className="flex flex-row items-end h-full ml-3">
            <button
                type="button"
                className="p-2 flex flex-col justify-center rounded focus:outline-none"
                onClick={onTrash}>
                <FiTrash className="text-blue-700" />
            </button>
            <button
                type="button"
                className="ml-3 p-2 flex flex-col justify-center rounded focus:outline-none"
                onClick={onPlus}>
                <FiPlus className="text-blue-700" />
            </button>
        </div>
    );
}