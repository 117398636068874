import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { UseTableCellProps } from 'react-table';
import ColoredContentButton from 'src/components/Basicos/ColoredContentButton';
import Table from 'src/components/Basicos/Table';
import ModalAdicionarEditar from 'src/components/Funcionalidades/Modal';
import ModalDelete from 'src/components/Funcionalidades/ModalDelete';
import { axiosApi } from 'src/services/axiosInstance';
import AnuncioPlano from '../FormsAuxiliar/_AnuncioPlano';
// import FuncionalidadePlanoForm from '../FormsAuxiliar/_FuncionalidadePlano';

type PlanoAnuncio = {
    id_plano_anuncio: number;
    ds_descricao_plano?: string;
    nr_quantidade_visualizacao?: number;
    valor_plano?: number;
    st_ativo: boolean;
}
const PlanosAnuncio: React.FunctionComponent = () => {

    const [data, setData] = useState<Array<PlanoAnuncio>>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<any>();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [index, setIndex] = useState<any>();

    const closeModal = (): void => {
        setOpen(false);
        setId(undefined);
    }
    const openModal = (): void => {
        setOpen(true);
    }

    const closeModalDelete = () => setOpenModalDelete(false);

    const openModalDeleteFunc = (id: number): void => {
        setOpenModalDelete(true);
        setId(id);
        setIndex(index);
    }


    const loadCategoria = async (): Promise<void> => {
        const response = await axiosApi.get('adm-plano-anuncio');
        setData(response.data.dados);
        console.log(response.data);
    }

    const getPlanoPeloIndice = (index: number): PlanoAnuncio | undefined => {
        return data[index] ?? undefined;
    }

    useEffect(() => {
        loadCategoria();
    }, [id])

    const columns = [
        {
            Header: "Código",
            accessor: "id_plano_anuncio"
        },
        {
            Header: "Descrição",
            accessor: "ds_descricao_plano"
        },
        {
            Header: "Impressões",
            accessor: "nr_quantidade_visualizacao"
        },
        {
            Header: "Valor",
            accessor: "valor_plano"
        },
        {
            Header: "Status",
            Cell: ({ row }: UseTableCellProps<PlanoAnuncio>) => (
                <div >
                    {row.original.st_ativo === true ? 'Ativo' : 'Inativo'}
                </div>
            ),
            accessor: "st_ativo"
        },
        {
            Header: "",
            resizable: true,
            accessor: "acoes",
            Cell: ({ row }: UseTableCellProps<PlanoAnuncio>) => {
                return (
                    <Fragment>
                        <div className="flex flex-row p-0 justify-center">
                            <ColoredContentButton className="btn-table "
                                onClick={() => {
                                    getPlanoPeloIndice(row.index);
                                    setId(row.index);
                                    setOpen(true);
                                }}
                            >
                                <FiEdit />
                            </ColoredContentButton>
                            <ColoredContentButton
                                className="btn-table"
                                name="delete"
                                onClick={() => {
                                    openModalDeleteFunc(row.original.id_plano_anuncio);
                                    getPlanoPeloIndice(row.index);
                                    setIndex(row.index);
                                }}
                            >
                                <FiTrash2 />
                            </ColoredContentButton>
                        </div>
                    </Fragment>
                );
            }
        }
    ]
    return (
        <Fragment>
            <div className="grid mt-24 ml-8 home-box sombra p-3 sm:grid-cols-12 md:grid-cols-6 lg:grid-cols-12">
                <p
                    className="main-home-titulo lg:col-start-1 col-span-1 mb-6"
                    style={{ width: 'max-content' }}
                >Planos</p>
                <span
                    onClick={() => openModal()}
                    style={{ cursor: 'pointer', marginLeft: '11rem' }} >
                    <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                </span>
                <div className="grid lg:col-span-12 lg:col-start-1">
                    <Table columns={columns} data={data} />
                </div>
            </div>
            <ModalAdicionarEditar
                open={open}
                closeModal={closeModal}
                titulo="plano"
                dados={id ?? undefined}
            >
                <AnuncioPlano
                    dados={id !== undefined ? getPlanoPeloIndice(id) : undefined}
                    closeModal={closeModal}
                    dataArray={data}
                />
            </ModalAdicionarEditar>
            <ModalDelete
                open={openModalDelete}
                closeModal={closeModalDelete}
                titulo={'plano'}
                rota={'adm-plano-anuncio'}
                id={id}
                index={index !== undefined ? getPlanoPeloIndice(index) : undefined}
                data={data}
            />
        </Fragment>
    );
}

export default PlanosAnuncio;