import React, { Fragment } from 'react';
import { TableTitleProps } from 'src/@types';
import { PlusBlueIcon } from 'src/assets/icons';

function TableTitle({ title, style, onClickToCreate, ...rest }: TableTitleProps) {
    return (
        <Fragment>
            <div className="flex flex-row items-center m-5">
                <p className="font-bold m-3">{title}</p>
                <span onClick={onClickToCreate} className="cursor-pointer">
                    <PlusBlueIcon />
                </span>
            </div>
        </Fragment>
    );
}

export default TableTitle;
