import React from 'react'

import setaEsquerda from '../../../../src/assets/icones/seta-esquerda-blue.png';
import setaDireita from '../../../../src/assets/icones/seta-direita-blue.png';

interface Props
{
    paraEsquerda?: boolean;
}

function Seta({ paraEsquerda, ...rest }: Props): React.ReactElement {
    return <img src={ paraEsquerda ? setaEsquerda : setaDireita } />;
}

export default Seta;
