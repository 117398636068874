import { useState } from "react";

/**
 * React Hook para caso de realizar alteração
 * em algum recurso pelo seu id (alteração ou exclusão).
 */
export default function useRowId(setOpenUpdateModal: Function, setOpenDeleteModal: Function) {
    /**
     * Estados do id do recurso que pode ser deletado ou atualizado.
     */
    const [rowId, setRowId] = useState<number | undefined>(undefined);
    const [rowAction, setRowAction] = useState<string | undefined>(undefined);

    /**
     * 
     * @param id 
     */
    function handleEdit(id: number | undefined) {
        setRowId(id);
        setRowAction('Editar');
        setOpenUpdateModal(true);
    }

    /**
     * 
     * @param id 
     */
    function handleDelete(id: number | undefined) {
        setRowId(id);
        setRowAction('Excluir');
        setOpenDeleteModal(true);
    }

    /**
     * 
     * @param id 
     * @param tableData 
     * @param resourceType 
     */
    function getResourceById(id: number, tableData: any[], resourceType: string) {
        return tableData.filter(el => el[resourceType] === id)[0];
    }


    return { rowId, setRowId, rowAction, handleEdit, handleDelete, getResourceById };
}