import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { UseTableCellProps } from 'react-table';
import ColoredContentButton from 'src/components/Basicos/ColoredContentButton';
import Table from 'src/components/Basicos/Table';
import ModalAdicionarEditar from 'src/components/Funcionalidades/Modal';
import ModalDelete from 'src/components/Funcionalidades/ModalDelete';
import { axiosApi } from 'src/services/axiosInstance';
import DuvidaForm from '../FormsAuxiliar/_DuvidasFrequentes';

type DuvidaProps = {
    id_duvida: number,
    id_cliente_usuario: number,
    descricao_duvida: string,
    resposta_duvida: string,
    tb_cliente_usuario: {
        id_cliente_usuario: number,
        nome_usuario: string
    }
}

const DuvidaFrequente: React.FunctionComponent = () => {
    const [data, setData] = useState<Array<DuvidaProps>>([]);
    const [open, setOpen] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [id, setId] = useState<any>();
    const [index, setIndex] = useState<any>();

    const closeModal = (): void => {
        setOpen(false);
        setId(undefined);
    }

    const closeModalDelete = () => setOpenModalDelete(false);

    const openModalDeleteFunc = (id: number): void => {
        setOpenModalDelete(true);
        setId(id);
    }

    const openModal = (): void => {
        setOpen(true);
    }
    const loadCategoria = async (): Promise<void> => {
        const response = await axiosApi.get('adm-duvida');
        setData(response.data.dados);
    }

    const getDuvidaPeloIndice = (index: number): DuvidaProps | undefined => {
        return data[index] ?? undefined;
    }

    useEffect(() => {
        loadCategoria();
    }, [])
    const columns = [
        {
            Header: "Código",
            accessor: "id_duvida"
        },
        {
            Header: "Pergunta",
            accessor: "descricao_duvida"
        },
        // {
        //     Header: "Status",
        //     Cell: ({ row }: UseTableCellProps<DuvidaProps>) => (
        //         <div >
        //             {row.original.st_ativo === true ? 'Ativo' : 'Inativo'}
        //         </div>
        //     ),
        //     accessor: "st_ativo"
        // },  
        {
            Header: "",
            resizable: true,
            accessor: "acoes",
            Cell: ({ row }: UseTableCellProps<DuvidaProps>) => {
                return (
                    <Fragment>
                        <div className="flex flex-row p-0 justify-center">
                            <ColoredContentButton className="btn-table "
                                onClick={() => {
                                    getDuvidaPeloIndice(row.index);
                                    setId(row.index);
                                    setOpen(true);
                                }}
                            >
                                <FiEdit />
                            </ColoredContentButton>
                            <button
                                className="btn-table"
                                name="delete"
                                onClick={() => {
                                    openModalDeleteFunc(row.original.id_duvida);
                                    getDuvidaPeloIndice(row.index);
                                    setIndex(row.index);
                                }}
                            >
                                <ColoredContentButton>
                                    <FiTrash2 />
                                </ColoredContentButton>
                            </button>
                        </div>
                    </Fragment>
                );
            }
        }
    ]
    return (
        <div className="flex mt-24 ml-8 home-box sombra p-3 grid sm:grid-cols-12 md:grid-cols-6 lg:grid-cols-12">
            <p
                className="main-home-titulo lg:col-start-1 col-span-1 mb-6 ml-4"
                style={{ width: 'max-content' }}
            >
                Dúvidas Frequentes
            </p>
            <span
                onClick={openModal}
                style={{
                    cursor: 'pointer',
                    marginLeft: '7rem'
                }}
            >
                <FontAwesomeIcon icon={faPlusCircle} size="2x" />
            </span>
            <div className="grid lg:col-span-12 lg:col-start-1">
                <Table columns={columns} data={data} />
            </div>
            <ModalAdicionarEditar
                open={open}
                closeModal={closeModal}
                titulo="Dúvida Frequente"
                dados={id ?? undefined}
            >
                <DuvidaForm
                    dados={id !== undefined ? getDuvidaPeloIndice(id) : undefined}
                    closeModal={closeModal}
                    dataArray={data}
                />
            </ModalAdicionarEditar>
            <ModalDelete
                open={openModalDelete}
                closeModal={closeModalDelete}
                titulo={'Dúvida Frequente'}
                rota={'adm-duvida'}
                id={id}
                index={index !== undefined ? getDuvidaPeloIndice(index) : undefined}
                data={data}
            />
        </div>
    );
}

export default DuvidaFrequente;