import { useEffect, useState } from 'react';
import { axiosApi } from 'src/services/axiosInstance';
import { IUseForm } from 'src/@types';
import { error as errorToast, jsonToFormData, success as successToast } from 'src/components/Basicos/funcoes';

export default function useForm<T>({ id, isMultipart = false, endpoint, formValues, callback = () => { } }: IUseForm) {

    const [data, setData] = useState<T>({} as T);

    /**
     * 
     */
    useEffect(() => {
        if (formValues) {
            setData(formValues);
        }
    }, [formValues]);

    /**
     * 
     */
    function onChangeInput(name: string, value: any) {
        setData((prevState) => ({ ...prevState, [name]: value }));
    }

    /**
     * 
     */
    function onChangeEventInput(event: any) {
        const { type, name, value, files } = event.target;
        setData({ ...data, [name]: type === 'file' ? files[0] : value });
    }

    async function onSubmit(event: any) {
        event.preventDefault();
        try {
            /**
             * Se não houver algum id passado ou for um MULTIPART form,
             * o método deve ser POST.
             */
            const httpMethod = (!id || !!isMultipart) ? 'POST' : 'PUT';

            const response = await axiosApi(
                {
                    /**
                     * Método HTTP (POST ou PUT).
                     */
                    method: httpMethod,
                    /**
                     * O cabeçalho da requisição depende da necessidade do
                     * formulário de ser multipart (envio de fotos, etc.) ou não. 
                     */
                    headers: {
                        'Content-Type': isMultipart ? 'multipart/form-data;' : 'application/json'
                    },
                    /**
                     * Se houver um id, será passado na url.
                     */
                    url: !!id ? `${endpoint}/${id}` : endpoint,
                    /**
                     * Se o formulário for do tipo multipart, os dados
                     * que seriam em JSON serão convertidos para form data.
                     */
                    data: isMultipart ? jsonToFormData(data, httpMethod) : data
                }
            );
            console.log(response);

            if (!!id) {
                successToast('Atualizado com sucesso!');
                callback();
            } else {
                successToast('Adicionado com sucesso!');
                callback();
            }

        } catch (error) {
            errorToast(error.response?.data?.mensagem)
        }
    }
    return {
        onSubmit,
        onChangeInput,
        onChangeEventInput,
        setData,
        data,
    };
};