import React, { useEffect, useState } from 'react';
import { jsonToFormData, success, warn } from 'src/components/Basicos/funcoes';
import { axiosApi } from 'src/services/axiosInstance';
import './style.css';

interface Dados {
    id?: number;
    // id_categoria?: number;
    arquivo?: any;
    id_arquivo?: any;
    ds_nome_imagem?: string;
    url?: string;
    nome?: string;
}

type Props = {
    dados?: Dados;
    closeModal: any;
    dataArray: any;
}

const CategoriaForm: React.FC<Props> = ({ dados, closeModal, dataArray }: Props) => {

    const [data, setData] = useState<Dados>({});

    const imageBackground = require('../../assets/main/foto-categoria-apagar-blue.png');

    const defineImageURL = (file: any) => file instanceof File ? URL.createObjectURL(file) : file;

    const onChangeEventInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value, } = event.target;
        setData({ ...data, [name]: value });
    }

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value, files, type } = event.target;
        setData({
            ...data,
            [name]: type === 'file' ? files ? files[0] : null : value
        });
    }

    useEffect(() => {

        if (dados) {
            setData({
                ...data,
                ds_nome_imagem: dados?.arquivo?.caminho ?? data.ds_nome_imagem,
                nome: dados.nome,
                id: dados.id,//apenas id
            });
        }
    }, [dados]);

    const submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            let formData = {}
            if(data?.ds_nome_imagem !== undefined && data?.ds_nome_imagem !== dados?.arquivo?.caminho && data?.ds_nome_imagem !== null){
                formData = {
                    ...data,
                    id_arquivo: data?.ds_nome_imagem,
                    arquivosUpload: [{
                        file: 'id_arquivo',
                        modulo: 'Categorias',
                        name: `img ${data?.nome}`,
                        inactive: data?.id_arquivo ? data?.arquivo?.id : null
                    }]
                }
            }else{
                formData ={
                    ...data, 
                }
            }
            const method = dados ? "PUT" : "POST";
            const urlFormated = dados ? `${'/crud/Categoria'}/${data?.id}` : '/crud/Categoria';
            const dataFormated = jsonToFormData(formData, method);
            const response = await axiosApi(
                {
                    method: 'POST',
                    headers: { "Content-Type": "multipart/form-data;" },
                    url: urlFormated,
                    data: dataFormated
                }
            );
            if (response.status === 201 && method === "POST") {
                const { dados } = response.data;
                success('Adicionado com sucesso!');
                dataArray.push(dados)
            } else {
                success('Atualizado com sucesso!');
            }
            closeModal();
        } catch (error) {
            warn("Erro ao salvar dados");
        }
    }

    return (
        <form className="flex flex-col my-5" onSubmit={submitForm} >
            <div id="NovaCategoria" className="content-icone-de-foto">
                <div className="foto-categoria">
                    <img
                        src={data?.ds_nome_imagem ? defineImageURL(data.ds_nome_imagem) : defineImageURL(imageBackground)}
                        className="foto-gategoria-imagem"
                    />
                </div>

                <div className="icone-de-foto">

                    <label htmlFor="selecao-arquivo">
                        <img src={require('../../assets/icones/foto.png')} />
                    </label>
                    <input
                        id="selecao-arquivo"
                        type="file"
                        name="ds_nome_imagem"
                        onChange={onChangeInput}
                    />
                </div>

            </div>

            <label className="form-titulo my-3">Descrição da Categoria</label>
            <input
                type="text"
                name="nome"
                onChange={onChangeEventInput}
                value={data.nome ?? ""}
                className="modal-input mb-3 pl-2"
            />
            <button className="btn">{dados ? 'Atualizar' : 'Salvar'}</button>
        </form>
    );
}
export default CategoriaForm;