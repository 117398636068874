import React, { Ref } from 'react';
import { IThreeColsOneRow } from 'src/@types/components';
import Select from '../Form/Select';

function ThreeColsOneRow(
    { handleRef, onChangeHandlers = [() => { }, () => { }], labels = ["One", "Two"], names = ["", ""], values = ["", ""], options = [], readOnly = false, children: icons }:
        IThreeColsOneRow
) {

    function FirstInput(_props: { value?: string | number | undefined; onChange?: React.ChangeEventHandler<HTMLElement> }) {
        if (!!readOnly) {
            return (
                <input
                    ref={handleRef}
                    className=
                    "w-full p-1 pl-3 font-bold text-sm modal-input"
                    value={_props?.value ?? ''}
                    onChange={_props?.onChange}
                    name={names[0]}
                    readOnly
                />
            );
        }

        return (
            <Select
                name={names[0]}
                value={_props?.value ?? ''}
                options={options}
                className="w-full p-1 modal-input pl-3 font-bold text-sm"
                onChange={_props?.onChange}
            />
        );
    }

    return (
        <div className="flex flex-row justify-between w-full mb-5">
            <div className="text-left w-4/5" >
                <label className="font-bold text-sm">{labels[0]}</label>
                <FirstInput value={values[0]} onChange={onChangeHandlers[0]} />
            </div>

            <div className="w-full flex flex-row items-center">
                <div className="text-left pl-3 flex flex-col">
                    <label className="font-bold text-sm">{labels[1]}</label>
                    <input
                        ref={handleRef}
                        readOnly={readOnly}
                        required
                        onChange={onChangeHandlers[1]}
                        type="number"
                        name={names[1]}
                        value={values[1] ?? 0}
                        style={{ padding: "0.150rem" }}
                        className="modal-input w-full pl-3 font-bold text-sm"
                    />
                </div>
                {icons}
            </div>

        </div>
    );
}

export default ThreeColsOneRow;