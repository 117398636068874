import React from 'react';
import { Redirect } from 'react-router';
import { axiosApi } from 'src/services/axiosInstance';

const Logout = ():JSX.Element => {
    localStorage.clear();
    axiosApi.defaults.headers.common['authorization'] = '';
    axiosApi.post('auth/logout');
    window.location.reload();
    return <Redirect to="/login" />
}
export default Logout;