import React, { ReactNode } from 'react';
import { AuthProvider } from './auth';
import { FormProvider } from './form';
import { ModalProvider } from './modal';

export default function ApplicationProvider({ children }: { children: ReactNode }) {
    return (
        <AuthProvider>
            <FormProvider>
                <ModalProvider>
                    {children}
                </ModalProvider>
            </FormProvider>
        </AuthProvider>
    );
}