import React, { Fragment } from 'react'
import FuncionalidadesDosPlanos from './FuncionalidadesDosPlanos';
import PlanosDeAssinaturas from './PlanosDeAssinaturas';

function PlanosPage() {

    console.log('planos')
    return (
        <Fragment>
            <FuncionalidadesDosPlanos />
            <PlanosDeAssinaturas />
        </Fragment>
    );
}

export default PlanosPage;
