import React, { Fragment } from 'react';
import { CustomInputProps } from 'src/@types';

function CustomInput({ label, type, name, onChange, value }: CustomInputProps) {

    return (
        <Fragment>
            <label className="text-left my-3">{label}</label>
            <input
                type={type}
                name={name}
                onChange={onChange}
                value={value}
                className="modal-input mb-3 p-1"
            />
        </Fragment>
    )
}

export default CustomInput
