import { useEffect, useState } from "react";

/**
 * React Hook para gerenciar estado dos modais de criação, edição e deleção.
 * @param initialState 
 */
export default function useModal(initialState: boolean = false) {
    const [openCreateOrUpdateModal, setOpenCreateOrUpdateModal] = useState(initialState);
    const [openDeleteModal, setOpenDeleteModal] = useState(initialState);

    useEffect(() => {
    }, [openCreateOrUpdateModal]);

    return { openCreateOrUpdateModal, setOpenCreateOrUpdateModal, openDeleteModal, setOpenDeleteModal };
}