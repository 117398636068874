import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip,CartesianGrid } from 'recharts';

import './style.css';

const Anuncios = () => {
    const data = [
        {
          name: '10', uv: 150, pv: 2400, amt: 2400,
        },
        {
          name: '5', uv: 3000, pv: 1398, amt: 2210,
        },
        {
          name: '10', uv: 2000, pv: 9800, amt: 2290,
        },
        {
          name: '15', uv: 2780, pv: 3908, amt: 2000,
        },
        {
          name: '20', uv: 1890, pv: 4800, amt: 2181,
        },
        {
          name: '25', uv: 2390, pv: 3800, amt: 2500,
        },
        {
          name: '30', uv: 3490, pv: 4300, amt: 2100,
        },
      ];
    return (
        <div className="flex-1 rounded-lg shadow shadow-md mt-3 p-4 ml-6">
            <p className="card-anuncio-titulo text-left">Anúncios</p>
             <div className="flex justify-between">
               <div className="flex-col">
                <span className="anuncio-numero-impressoes">3000</span>
                <p className="anuncio-impressoes">Impressoes</p>
               </div>
               <div>
               <p className="card-usuario-info mt-2">
                 <FontAwesomeIcon icon={faArrowUp} />&nbsp;
                 0,00% desde o mes passado</p>
               </div>
             </div>
             
            <AreaChart
                width={310}
                height={100}
                data={data}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#00FFCC" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#00FFCC" stopOpacity={0} />
                    </linearGradient>
                </defs>
                {/* <XAxis dataKey="name" /> */}
                {/* <YAxis /> */}
                <Tooltip />
                <Area type="monotone"
                    dataKey="uv"
                    stroke="#00FFCC"
                    fill="url(#colorUv)"
                    fill-opacity={1}
                    strokeWidth={5}
                    className="stroke"
                />
            </AreaChart>
            <div className="flex justify-between">
               <div className="flex-col" style={{
                 borderRight: '2px solid #ECE9F1',
                 paddingRight: '20px'
               }}>
                  <span className="anuncio-numero-impressoes">15</span>
                  <p className="anuncio-impressoes">Anúncios</p>
                  <p className="anuncio-impressoes">Ativos</p>
               </div>
               <div className="flex-col" style={{
                 borderRight: '2px solid #ECE9F1',
                 paddingRight: '20px'
               }}>
                  <span className="anuncio-numero-impressoes">70</span>
                  <p className="anuncio-impressoes">Anúncios</p>
                  <p className="anuncio-impressoes">Públicados</p>
               </div>
               <div className="flex-col">
                  <span className="anuncio-numero-impressoes">15</span>
                  <p className="anuncio-impressoes">Anunciantes</p>
               </div>
            </div>   
        </div>
    );
}

export default Anuncios;