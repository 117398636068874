import React, { ChangeEvent, useEffect, useState } from 'react'
import CustomInput from 'src/components/Basicos/CustomInput';
import CustomTextArea from 'src/components/Basicos/CustomTextArea';
import SelectForm from 'src/components/Basicos/Form/SelectForm';
import { ToggleSwitch } from 'src/components/Basicos/ToggleSwitch';
import useForm from 'src/hooks/useForm/index';
import {
    FuncionalidadeDosPlanosFormProps,
    FuncionalidadePlanoProps,
    ISelectOption
} from 'src/@types';

export function FuncionalidadeDosPlanosForm({
    rowId,
    tiposDeFuncionalidades,
    formValues,
    reloadFunction,
    setOpen,
}: FuncionalidadeDosPlanosFormProps
): JSX.Element {

    const { onSubmit, onChangeInput, data } = useForm<FuncionalidadePlanoProps>(
        {
            id: rowId,
            endpoint: 'adm-funcionalidade-plano',
            formValues,
            callback: reloadFunction
        });
    /**
     * Estado das options do select.
     */
    const [optionsTipos, setOptionsTipos] = useState<ISelectOption[]>([]);

    function handleSubmit(e: any) {
        onSubmit(e);
        setOpen(false);
    }

    useEffect(() => {
        (() => {
            /**
             * Cria array array vazio para ser populado posteriormente.
             */
            let selectOptions: any[] = [];
            /**
             * Mapeia os tipos de funcionalidades recebidos como props
             * e seta o value e a label dos options com base na iteração.
             */
            tiposDeFuncionalidades?.map(tipo => {
                selectOptions = selectOptions.concat({
                    value: tipo.id_tipo_funcionalidade,
                    label: tipo.descricao,
                });
                setOptionsTipos(selectOptions);
            });
        })()
    }, []); // useEffect

    return (
        <form className="flex flex-col" onSubmit={handleSubmit} >
            <CustomInput
                label="Nome"
                name="nome_funcionalidade"
                type="text"
                value={data?.nome_funcionalidade ?? ""}
                onChange={((e: ChangeEvent<HTMLInputElement>) => onChangeInput(e.target.name, e.target.value))}
            />
            <SelectForm
                label="Tipo"
                name="id_tipo_funcionalidade"
                value={optionsTipos.find(element => element.value === data?.id_tipo_funcionalidade)}
                options={optionsTipos}
                onChange={((name: string, value: string | number | undefined) => onChangeInput(name, value))}
                placeholder="Selecione uma Subcategoria"
                className="modal-input mt-3 p-1"
            />
            <CustomTextArea
                label="Descrição"
                name="ds_funcionalidade"
                value={data?.ds_funcionalidade ?? ""}
                onChange={((e: ChangeEvent<HTMLTextAreaElement>) => onChangeInput(e.target.name, e.target.value))}
            />
            <div className="flex flex-row mt-6 mb-3 justify-between">
                <label className="text-left">Status</label>
                <ToggleSwitch
                    defaultValue={data?.st_ativo}
                    toggleStates={{ on: 'Ativo', off: 'Inativo' }}
                    callback={(isActive) => onChangeInput('st_ativo', isActive)}
                />
            </div>
            <button className="btn">Salvar</button>
        </form>
    );
}