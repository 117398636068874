import React from 'react';
import Popup from 'reactjs-popup';
import { ModalCreateOrUpdateProps } from 'src/@types';
import "./style.css"

function ModalCreateOrUpdate
  ({ open, setOpen, title, children, rowId, isMale = false }: ModalCreateOrUpdateProps): JSX.Element {

  function handleCloseModal() {
    setOpen(false);
  }

  return (
    <div>
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={handleCloseModal}>
        {(closeModal: any) => (
          <div className="modal">
            <div className="topo-modal-adicionar-editar">
              <div className="icone-voltar-modal" onClick={closeModal}>
                <img src={require('../../../assets/icones/seta-esquerda.png')} />
              </div>
              <div className="titulo-modal-adicionar-voltar">
                <h1>{ !!rowId ? `${'Atualizar'} ${title}` : `${!!isMale ? 'Novo' : 'Nova'} ${title}` }</h1>
              </div>
            </div>
            <div className="conteudo-modal-adicionari-editar">
              {children}
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default ModalCreateOrUpdate;