import React, { Fragment, useCallback, useState } from "react";
import { BarChart, Bar, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, LabelList } from 'recharts';
import './style.css';
// const data = [
//     {
//         name: '1', uv: 150, pv: 2400, amt: 2400,numero:50
//     },
// ];

const capitalize = (string) =>
{
  return string[0].toUpperCase() + string.slice(1);
}

const BarGraphCard = ({dadosBar}) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = useCallback(
        (_, index) => {
        setActiveIndex(index);
        },
        [setActiveIndex]
    );

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
      
        return (
          <g transform={`translate(${x},${y})`}>
            <text
              x={0}
              y={0}
              dy={16}
              textAnchor="middle"
              fill="#666"
            >
              {capitalize(payload.value)}
            </text>
          </g>
        );
      };
    return (
        <Fragment>
            {(Object.keys(dadosBar).length !== 0) ?
            <div className="flex-1 rounded-lg shadow shadow-md mt-4 p-4 ml-6" >
            <p className="text-left card-contrato-plano-titulo">Contrato de Planos</p>
                <div className="flex-row">
                  <ResponsiveContainer width="90%" height={240}>
                    <BarChart 
                      width={"60%"} 
                      height={40} 
                      data={dadosBar}
                      margin={{ top: 50, right: 30, left: 20, bottom: 5 }}>
                        <XAxis dataKey="name" tick={<CustomizedAxisTick />}  />
                        <Bar dataKey="value" onClick={handleClick} position="top">
                        <LabelList className="title-bar" dataKey="value" position="top" />
                        {dadosBar.map((entry, index) => (
                            <Cell
                            cursor="pointer"
                            fill={index === activeIndex ? "#223066" : "#4bfdcc"}
                            key={`cell-${index}`}
                            />
                        ))}
                        </Bar>
                    </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            :""}
        </Fragment>
    );
}
export default BarGraphCard;

