import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Home from '../../../pages/Home';
import Categoria from '../../../pages/Categoria';
import Planos from '../../../pages/Planos';
import Usuarios from '../../../pages/Usuarios';
import Anuncios from '../../../pages/Anuncios';
import Duvidas from '../../../pages/DuvidaFrequentes';
import Logout from '../../../pages/Logout';
import PlanosAnuncio from 'src/pages/PlanosAnuncio';
import Anunciantes from 'src/pages/Anunciaentes';
import Consultas from 'src/pages/Consultas';

const Conteudo = (): JSX.Element => {
    return (
        <Fragment>
            <div style={classes.container}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/categorias" component={Categoria} />
                    <Route exact path="/planos" component={Planos} />
                    <Route exact path="/planos/anuncio" component={PlanosAnuncio} />
                    <Route exact path="/anunciantes" component={Anunciantes} />
                    <Route path="/usuarios" component={Usuarios} />
                    <Route exact path="/anuncios" component={Anuncios} />
                    <Route exact path="/duvidas" component={Duvidas} />
                    <Route exact path="/consultas" component={Consultas} />
                    <Route exact path="/auth/logout" component={Logout} />
                    <Redirect to="/" />
                </Switch>
            </div>
        </Fragment>
    );
}

const classes = {
    container: {
        marginTop: '4.5rem',
        marginLeft: '8rem',
    },
};

export default Conteudo;