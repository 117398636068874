import { faArrowUp, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { axiosApi } from 'src/services/axiosInstance';
import './style.css';

const CardUsuario: React.FunctionComponent = () => {

    const [quantidadeDeusuarios, setQuantidadeDeUsuarios] = useState(0)
    const [percentualDeUsuariosNovos, setPercentualDeUsuariosNovos] = useState(0)
    const [houveCrescimento, setHouveCrescimento] = useState(false)

    const loadDadosUsuarios = async (): Promise<void> => {
        const response = await axiosApi.get('adm-controle-usuarios');
        setQuantidadeDeUsuarios(response.data.qtd_usuarios)
        setPercentualDeUsuariosNovos(response.data.percentual_usuarios_novos)
        setHouveCrescimento(response.data.crescimento)
    }

    useEffect(() => {
        loadDadosUsuarios();
    }, []);

    return (
        <div className="grid sm:grid-cols-12 md:grid-cols-6 lg:grid-cols-1 rounded-lg shadow shadow-md p-4 ml-3 w-full">
            <div className="flex space-x-4">
                <span className="card-usuario-titulo">Usuários</span>
                <FontAwesomeIcon icon={faUser} />
            </div>
            <p className="card-usuario-numero">{quantidadeDeusuarios}</p>
            <p className="card-usuario-info">
                {houveCrescimento && <FontAwesomeIcon icon={faArrowUp} />}&nbsp;
                {percentualDeUsuariosNovos} desde o mês passado
            </p>
        </div>
    )
}

export default CardUsuario;