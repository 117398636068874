import React, { Ref, useEffect, useState } from 'react';
import { IFormTabs } from 'src/@types/components';
import { useFormContext } from 'src/context/form';

interface ITabGeral{
    handleRef?: Ref<any>;
    nomePlano: string | undefined;
    setNomePlano: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export default function TabGeral(props: ITabGeral) {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => (
        props?.setNomePlano(e.target.value) // tb_plano_assinatura.ds_nome_plano_assinatura
    );

    return (
        <div className="flex flex-col">
            <label className="form-label-titulo font-bold text-sm mb-3">Descrição do Plano</label>
            <input
                required
                onChange={handleInputChange}
                value={props?.nomePlano ?? ''}
                type="text"
                name="tb_plano_assinatura.ds_nome_plano_assinatura"
                className="modal-input p-1 pl-3 font-bold text-sm espaco"
                ref={props?.handleRef}
            />
        </div>
    );
};