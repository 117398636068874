import React, { useEffect, useState } from 'react';
import { IFormTabs } from 'src/@types/components';
import { IPeriodicidadePlanoAssinatura, IValorPlanoAssinatura } from 'src/@types/entities';
import ListThreeColsOneRow from 'src/components/Basicos/ListThreeColsOneRow';
import { useFormContext } from 'src/context/form';
import { useSelectOptions } from 'src/hooks/useSelectOptions';


interface ITabValores extends IFormTabs<IValorPlanoAssinatura[] | undefined> {
    options: IPeriodicidadePlanoAssinatura[] | undefined;
    valores: IValorPlanoAssinatura[] | undefined;
    setValores: React.Dispatch<React.SetStateAction<IValorPlanoAssinatura[] | undefined>>;
}

export default function TabValores(props: ITabValores): JSX.Element {

    const { options } = useSelectOptions<IPeriodicidadePlanoAssinatura>(
        props.options ?? [{ id_periodicidade_plano_assinatura: 1, descricao: '' }],
        element => ({
            value: element.id_periodicidade_plano_assinatura,
            label: `${element.id_periodicidade_plano_assinatura.
                toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} - ${element.descricao}`,
        })
    );

    return (
        <ListThreeColsOneRow <IValorPlanoAssinatura>
            parentItemName="tb_valor_plano_assinatura"
            names={['id_periodicidade_plano_assinatura', 'vr_valor_plano_assinatura']}
            labels={['Periodicidade', 'Valor']}
            options={options}
            items={props?.valores}
            getListItems={items => props.setValores(items)}
        />
    );
}