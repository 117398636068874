import React, { createContext, useContext, useEffect, useState } from 'react';

interface FormContextAttributes {
    /**
     * Dados do formulário.
     */
    data: any;
    /**
     * Seta objeto de dados do formulário.
     */
    setData: React.Dispatch<React.SetStateAction<object | null>>;
    /**
     * Limpa os dados do formulário.
     */
    clear(): void;
    /**
     * Adiciona um item ao objeto de dados do formulário.
     */
    add(name: string, value: any): void;
}

const FormContext = createContext<FormContextAttributes>({} as FormContextAttributes);

function FormProvider(props: { children: React.ReactNode }) {

    const [data, setData] = useState<object | null>(null);

    useEffect(() => {
        console.log(data);
    }, [data]);

    const add = (name: string, value: any) => {
        
        const parts = name.split('.', 2);

        if (parts.length === 2) {
            const item = parts[0];
            const name = parts[1];
            setData(prevState => {
                const prevItem = (!!prevState) ? prevState[item] : [];
                return { ...prevState, [item]: { ...prevItem, [name]: value } };
            });
            return;
        }
        setData((prevState) => ({ ...prevState, [name]: value }));
    }

    const clear = () => {
        setData(null);
    }

    return (
        <FormContext.Provider value={{ data, setData, clear, add }}>
            {props.children}
        </FormContext.Provider>
    );
}

function useFormContext() {
    const context = useContext(FormContext);
    return context;
}

export { FormContext, FormProvider, useFormContext };