import React, { Fragment, useCallback, useState, useEffect } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell, Legend, Label } from "recharts";
// import "./styles.css";
import { axiosApi } from '../../../services/axiosInstance';

const capitalize = (string) =>
{
  return string[0].toUpperCase() + string.slice(1);
}

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#2c2929"}>
        Usuários {total}
      </text> */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${capitalize(payload.name)} (${value})`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const PieGraph = ({ dadosPie }) => {

  const CustomLabel = props => {
    console.log(props);
    return (
      <g>
        <rect
          x={props.viewBox.x}
          y={props.viewBox.y}
          fill="#aaa"
          width={100}
          height={30}
        />
        <text x={props.viewBox.x} y={props.viewBox.y} fill="#111" dy={20} dx={30}>
          Label
        </text>
      </g>
    );
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const COLORS = ["#223066", "#4bfdcc"];

  return (
    <Fragment>
      
      {(Object.keys(dadosPie).length !== 0) ?
        <div className="flex-1 rounded-lg shadow shadow-md mt-4 p-4 ml-1">
          <p className="card-tipo-usuario-titulo text-left">Tipos de Usuários</p>
          <ResponsiveContainer width="90%" height={260}>
            <PieChart width={250} height={"50%"}>
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={dadosPie.slice(1, 3)}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                {dadosPie.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                                  
                {/* <Label value={`Usuários ${dadosPie[0].value}`} offset={0} position="center" /> */}
                <Label className="label-center-pie" offset={0} position="center">
                  {`${dadosPie[0].value} Usuário`}
                </Label>
              </Pie>
              <Legend
                payload={
                  dadosPie.slice(1, 3).map(
                    (item, index) => ({
                      id: item.name,
                      type: "square",
                      value: `${capitalize(item.name)}`,
                      color: COLORS[index % COLORS.length]
                    })
                  )
                } 
                layout="vertical"
                verticalAlign="middle"
                align="right"
                iconType="square"/>
            </PieChart>
          </ResponsiveContainer>
        </div>
        : ''}
    </Fragment>
  );
}
export default PieGraph;